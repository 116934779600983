import { KeyboardEvent as ReactKeyboardEvent } from 'react';
import { Key } from 'w3c-keys';

/**
 * Checks if a keyboard input was a 'select all' command.
 *
 * One caveat is that this method doesn't work universally, as the key combos
 * used for keyboard shortcuts such as Select All differ between locales.
 * EX-51085 tracks the work we plan to do to handle internationalization of the
 * select all action.
 *
 * @param e The input keyboard event
 */
export const isSelectAllCommand = (
    e:
        | KeyboardEvent
        | ReactKeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
): boolean => (e.key === Key.A || e.key === Key.a) && (e.metaKey || e.ctrlKey);
