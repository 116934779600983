/**
 * Base error for when the user lacks the ability to see a resource or perform
 * an operation.
 *
 * @public
 */
export class AuthorizationError extends Error {
    /**
     * @param message A dev-facing error string to include in logs
     * @param taskId A program-facing ID that should be used to choose an error
     * string, such as `records.view`. Task IDs generally shouldn't be dynamic;
     * instead of passing `detections.view:15`, subclass `AuthorizationError`
     * and add a new property for the detection handle.
     *
     * There is deliberately no cross-vertical master list or enum of task IDs,
     * as enforcing that at compile time would create a dependency from the
     * `errors` module to some app-global list.
     */
    constructor(
        message: string,
        public readonly taskId?: string,
    ) {
        super(message);
    }
}

/**
 * Error for when there is no authenticated user. This usually implies a session
 * expiration, in which case the UI should prompt the user to log in again.
 *
 * @public
 */
export class NotLoggedInError extends Error {
    constructor() {
        super('No authenticated user');
    }

    public forceLogin(): void {
        window.location.reload();
    }
}
