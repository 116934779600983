import { hasProperty, isString } from '@extrahop/type-utils';

/**
 * Represents an available language that should be surfaced in the UI. May be
 * updated via cloud updates.Currently only contains the language code (language
 * tag), but may include more properties in the future.
 */
export interface Language {
    /**
     * Language code
     */
    code: string;
}

export const Language = {
    guard: (x: unknown): x is Language => hasProperty(x, 'code', isString),
};
