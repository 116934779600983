/**
 * Formatting helper for converting handles and other values into data that will
 * render well in an error message.
 *
 * @public
 */
export const formatForMessage = (handle: unknown): unknown => {
    if (typeof handle !== 'object' || handle === null) return handle;
    const handleToString = handle.toString();
    if (handleToString !== '[object Object]') return handleToString;
    try {
        return JSON.stringify(handle);
    } catch (e) {
        return '[Unable to serialize handle]';
    }
};
