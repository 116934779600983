import { ObservableField } from '@extrahop/observable-field';

import { getAutoLanguage } from './AutoLanguage';
import { loadLanguageResourceBundles } from './Loader';
import i18next from 'i18next';

export const isActiveLanguagePristine = new ObservableField(true);

/**
 * Get the display name for a given BCP47 language tag, as written in the given
 * language. Uses the browser's `Intl.DisplayNames()` API.
 * @param languageTag BCP47 language tag to localize
 * @returns The language's display name
 */
export const getLocalizedDisplayName = (languageTag: string): string =>
    new Intl.DisplayNames(languageTag, { type: 'language' }).of(languageTag) ??
    languageTag;

/**
 * Indicates whether the language has partial/incomplete translations. Right now
 * this simply return true for everything that isn't `en`.
 * @param languageTag BCP47 language tag to check
 * @returns Whether the translations are partial
 */
export const isLanguagePartial = (languageTag: string): boolean =>
    languageTag.split('-')[0]?.toLowerCase() !== 'en';

/**
 * Change the active language used by i18next. This does not set the user's
 * language preference, @see setPreferredLanguage().
 * @param targetLanguage The language we are setting. This function will fall
 * back to the default language for the following values: `''`, `null`, `undefined`.
 */
export const changeActiveLanguage = async (
    targetLanguage: string | null | undefined,
    isInitializor: boolean = false,
): Promise<void> => {
    const lang = targetLanguage || getAutoLanguage();
    if (lang === i18next.language) return;
    await loadLanguageResourceBundles(lang);
    i18next.changeLanguage(lang);
    if (!isInitializor) isActiveLanguagePristine.set(false);
};
