import { KeyboardEvent as ReactKeyboardEvent } from 'react';
import { Key } from 'w3c-keys';

/**
 * Copy `value` to the user's clipboard.
 *
 * # Caveats
 * * This should only be called in an event handler to avoid browser permission
 *   problems.
 * * This may steal focus from the previously-active element. The caller may
 *   need to restore focus after the copy is complete.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const copyToClipboard = (value: string) => {
    const temp = document.createElement('input');
    temp.type = 'text';
    temp.value = value;
    document.body.appendChild(temp);
    temp.select();
    try {
        // eslint-disable-next-line deprecation/deprecation
        document.execCommand('copy');
    } catch (_) {
        console.error('Failed to access clipboard');
    }
    document.body.removeChild(temp);
};

/**
 * Checks if a keyboard input was a 'copy' command. Depending on the platform,
 * this is Ctrl-C or Cmd-C.
 *
 * @param e The input keyboard event
 */
export const isCopyCommand = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    e: KeyboardEvent | ReactKeyboardEvent<any>,
): boolean => (e.key === Key.C || e.key === Key.c) && (e.metaKey || e.ctrlKey);
