import { RemoteData } from '@extrahop/ts-remote-data';

/**
 * Declares that a component does not accept children. The component's props
 * interface should extend this interface.
 */

declare const _brand: unique symbol;

export interface Brand<B> {
    /**
     * Type system marker not present at runtime, used to add nominal typing
     * to structurally-equivalent types.
     */
    readonly [_brand]: B;
}

/**
 * A nominal type wrapper that prevents structural type equivalence. This is
 * useful for differentiating primitive types such as `number` or `string`, or
 * for preventing manipulation of underlying primitive types if the type's
 * internal representation may change in the future.
 *
 * # Example
 * ```typescript
 * type JsonPath = Opaque<'JsonPath', string>;
 * ```
 */
export type Opaque<K, T> = T & Brand<K>;

/**
 * A custom invalid error state, to be used sparingly by library code when
 * `never` would not make the actual root cause of the problem clear.
 *
 * Note that and'ing `Invalid` to most other types will result in `never`, so it
 * should be avoided.
 *
 * See https://github.com/microsoft/TypeScript/issues/23689 for a proposal for a
 * built-in alternative.
 *
 * @example
 * // Here `Symbol` and `Formatter` come from parsing a string in a JSON file.
 * // The error only appears when the same symbol is used. Simply using `never`
 * // would cause an error, but the error message would not give a hint about
 * // what the problem is. `Invalid` comes to the rescue by sneaking in and
 * // printing some error message.
 * type SymbolValue<
 *     Symbol extends string,
 *     Formatter extends string = '',
 * > = Symbol extends 'count'
 *         ? Formatter extends 'number'
 *             ? FormatterValue['number']
 *             // We would typically use `never`, but the cause of the problem
 *             // would be lost.
 *             : Invalid<'`count` should be formatted as a number'>
 *         : Etc;
 */
export type Invalid<ErrorMessage> = Opaque<'Invalid', ErrorMessage>;

/**
 * Get the union of properties from a const array of unknown.
 */
export type ArrayMembers<T extends Readonly<unknown[]>> = T[Extract<
    keyof T,
    number
>];

export type SetMembers<T> = T extends Set<infer K> ? K : never;

/**
 * A view of some `TResource` containing only the fields from the `TKeys` array.
 * This is akin to the (objectified) result of a SQL `SELECT` statement.
 *
 * @template TResource The type being selected from
 * @template TKeys The readonly array of property keys that limit the selected
 * view.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Select<TResource, TKeys extends Readonly<any[]>> = Pick<
    TResource,
    ArrayMembers<TKeys>
>;

/**
 * An asynchronously-available view of some caller-requested properties on a
 * resource.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RemoteSelect<TResource, TKeys extends Readonly<any[]>> = RemoteData<
    Select<TResource, TKeys>
>;

/**
 * An asynchronously-available view of some caller-requested properties from an
 * array of handles that may not be immediately available.
 */
export type RemoteSelectMany<
    TResource,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    TKeys extends Readonly<any[]>,
> = RemoteData<Select<TResource, TKeys>[]>;

/**
 * An array of property keys into `T` that is known at compile time.
 */
export type KeyArray<T> = Readonly<(keyof T)[]>;

/**
 * An array of values for fields from `T`. The order of elements must
 * be known to consumers, so this type has no standalone value.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type ValueArray<T extends {}> = T[keyof T][];

/**
 * Zips `keys` and `values` together, casting as `T`.
 * WARNING: this function is unsafe at compile time because the order and
 * length of `keys` and `values` are assumed to match.
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const unsafeZipObject = <T extends {}>(
    keys: (keyof T)[],
    values: T[keyof T][],
): T => {
    if (keys.length !== values.length) console.warn('Order mismatch');
    return keys.reduce<Partial<T>>((memo, key, i) => {
        memo[key] = values[i];
        return memo;
    }, {}) as unknown as T;
};

/**
 * Get the keys of a `TRecord` whose values extend `TMatch`.
 *
 * More information
 * [here](https://medium.com/@bterlson/strongly-typed-event-emitters-2c2345801de8)
 */
export type MatchingKeys<
    TRecord,
    TMatch,
    K extends keyof TRecord = keyof TRecord,
> = K extends (TRecord[K] extends TMatch ? K : never) ? K : never;

/**
 * A selectively-partial type.
 *
 * @template T The base type
 * @template F The fields to be marked optional
 */
export type PartialFields<T, F extends keyof T> = Pick<T, Exclude<keyof T, F>> &
    Partial<Pick<T, F>>;

/**
 * Strips `readonly` from `T`. We use this so that we can define our values
 * with `as const` without the baggage of rewriting all consumers to accept
 * `readonly`. In the future we would love to have a TS flag that would
 * assume immutability as a default... and don't want `readonly` to infect
 * all of our code in the meantime.
 *
 * WARNING: Do not use this unless you're sure you have to.
 */
export type MutableArray<T> = T extends readonly (infer K)[] ? K[] : never;

/**
 * Ensures a discriminated union is exhaustive when used in an `else` or
 * `default` position.
 *
 * @argument fallback A fallback is required. If no fallback is possible, e.g.
 * if the application cannot continue without throwing an exception, then pass
 * fallback of `null` and throw `new UnreachableError()`.
 */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function assertNever<T>(value: never, fallback: T): T {
    console.error(`Switch fallthrough: ${JSON.stringify(value)}`);
    return fallback;
}

/**
 * Check that a value is neither null nor undefined, and narrow its type
 * accordingly.
 */
export const isNonVoid = <T>(
    val: T | null | undefined | void,
): val is NonNullable<T> => !isVoid(val);

/**
 * Check if the given `val` is `null` or `undefined`, and narrow its type
 * accordingly.
 */
export const isVoid = (val: unknown): val is null | undefined =>
    val === null || val === undefined;

/**
 * Type-guard for asserting that `val` is a list of numbers.
 * Will return true for empty lists and any list where list[0] is a number.
 * This means that heterogenuous lists will compile as number[] even though
 * they aren't number[]!!! We do it this way as a performance->correctness
 * tradeoff.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isListOfNumbers = (val: any): val is number[] =>
    val &&
    Array.isArray(val) &&
    (val.length === 0 || typeof val[0] === 'number');

type TypeGuard<T> = (val: unknown) => val is T;

/**
 * Type guard for asserting that `obj` has a property of `propName`.
 * This is very useful for other type guard functions since
 * `Object.prototype.hasOwnProperty` is not type guard ready.
 *
 * Accepts an optional typeguard function that additionally
 * asserts that the property is of a certain type. If no
 * typeguard function is passed, the property will default to
 * `unknown`.
 *
 * Note: this only works for direct properties. Use the `in` keyword to check
 * for inherited properties, which will also narrow the type.
 *
 * @example
 * const obj: unknown = { name: ' Bob ' };
 * if (hasProperty(obj, 'name')) {
 *     console.log(obj.name); // narrowed to { name: unknown }
 * }
 *
 * @example
 * const obj: unknown = { name: ' Bob '};
 * if (hasProperty(obj, 'name', isString)) {
 *     console.log(obj.name.trim()); // narrowed to { name: string }
 * }
 */
export const hasProperty = <
    PropName extends string | symbol,
    AssertedType = unknown,
>(
    obj: unknown,
    propName: PropName,
    typeguard?: TypeGuard<AssertedType>,
): obj is { [P in PropName]: AssertedType } => {
    if (
        typeof obj !== 'object' ||
        obj === null ||
        !obj.hasOwnProperty(propName)
    ) {
        return false;
    }
    if (!typeguard) return true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return typeguard((obj as any)[propName]);
};

/**
 * Type guard for asserting that `obj` either:
 * 1) does not have property `propName`, OR
 * 2) has property `propName` and its value is undefined, OR
 * 3) has property `propName` and its value is `AssertedType`
 *
 * `PropName` should be inferrable based on `propName`
 * `AssertedType` should be inferrable based on `TypeGuard`
 */
export const hasOptionalProperty = <PropName extends string, AssertedType>(
    obj: unknown,
    propName: PropName,
    typeguard?: TypeGuard<AssertedType>,
): obj is { [P in PropName]: AssertedType } => {
    if (typeof obj !== 'object' || obj === null) return false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((obj as any)[propName] === undefined) return true;
    if (!typeguard) return true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return typeguard((obj as any)[propName]);
};

export const makeStringUnionGuard = <T extends string>(
    ...args: T[]
): TypeGuard<T> => {
    const setOfStrings = new Set<T>(args);
    return (val: unknown): val is T =>
        typeof val === 'string' && isSetMember(setOfStrings, val);
};

/**
 * Returns a type guard that checks if its argument is `===` to the constant
 * value `val`.
 *
 * Intended to be used with literal values that respect value equality, e.g.
 *
 * @example
 * const lit: TypeGuard<"literal"> = isConst('literal')
 */
export const isConst =
    <T extends string | number | symbol | boolean>(val: T): TypeGuard<T> =>
    (value): value is T =>
        val === value;

interface IsAnyOf {
    <T1>(checker1: TypeGuard<T1>): TypeGuard<T1>;
    <T1, T2>(
        checker1: TypeGuard<T1>,
        checker2: TypeGuard<T2>,
    ): TypeGuard<T1 | T2>;
    <T1, T2, T3>(
        checker1: TypeGuard<T1>,
        checker2: TypeGuard<T2>,
        checker3: TypeGuard<T3>,
    ): TypeGuard<T1 | T2 | T3>;
    <T1, T2, T3, T4>(
        checker1: TypeGuard<T1>,
        checker2: TypeGuard<T2>,
        checker3: TypeGuard<T3>,
        checker4: TypeGuard<T4>,
    ): TypeGuard<T1 | T2 | T3 | T4>;
    <T1, T2, T3, T4, T5>(
        checker1: TypeGuard<T1>,
        checker2: TypeGuard<T2>,
        checker3: TypeGuard<T3>,
        checker4: TypeGuard<T4>,
        checker5: TypeGuard<T5>,
    ): TypeGuard<T1 | T2 | T3 | T4 | T5>;
    <T1, T2, T3, T4, T5, T6>(
        checker1: TypeGuard<T1>,
        checker2: TypeGuard<T2>,
        checker3: TypeGuard<T3>,
        checker4: TypeGuard<T4>,
        checker5: TypeGuard<T5>,
        checker6: TypeGuard<T6>,
    ): TypeGuard<T1 | T2 | T3 | T4 | T5 | T6>;
    <T1, T2, T3, T4, T5, T6, T7>(
        checker1: TypeGuard<T1>,
        checker2: TypeGuard<T2>,
        checker3: TypeGuard<T3>,
        checker4: TypeGuard<T4>,
        checker5: TypeGuard<T5>,
        checker6: TypeGuard<T6>,
        checker7: TypeGuard<T7>,
    ): TypeGuard<T1 | T2 | T3 | T4 | T5 | T6 | T7>;
    // XXX: add more here if needed... but also maybe reconsider your data model
}

export const isAnyOf: IsAnyOf =
    <T>(...guards: TypeGuard<T>[]): TypeGuard<T> =>
    (val: unknown): val is T =>
        guards.some(guard => guard(val));

export const isListOf =
    <T>(typeguard: TypeGuard<T>): TypeGuard<T[]> =>
    (val: unknown): val is T[] => {
        if (!val || !Array.isArray(val)) return false;
        if (val.length === 0) return true;
        const first100 = val.slice(0, 100);
        // Some typeguard functions could take an optional 2nd or 3rd function
        // argument. Since `Array.every`'s callback function actually populates the
        // 2nd argument with the index and 3rd with the original array, we explicity
        // call the typeguard function by ONLY passing in the first (value)
        // argument.
        return first100.every(v => typeguard(v));
    };

export const isNonEmptyListOf =
    <T>(typeguard: TypeGuard<T>): TypeGuard<T[]> =>
    (val: unknown): val is [T, ...T[]] =>
        Array.isArray(val) && val.length > 0 && isListOf(typeguard)(val);

export const isNonEmptyList = <T>(val: T[]): val is [T, ...T[]] =>
    val.length > 0;

export const isListOfUnknown = (val: unknown): val is unknown[] =>
    Array.isArray(val);

export const isNull = (val: unknown): val is null => val === null;

export const isString = (val: unknown): val is string =>
    typeof val === 'string';
export const isStringArray = isListOf(isString);
export const isNumber = (val: unknown): val is number =>
    typeof val === 'number';
export const isNumberArray = isListOf(isNumber);
export const isBoolean = (val: unknown): val is boolean =>
    val === true || val === false;
export const isBooleanTrue = (val: unknown): val is boolean => val === true;
export const isRegExp = (val: unknown): val is RegExp => val instanceof RegExp;
// eslint-disable-next-line @typescript-eslint/ban-types
export const isObject = (val: unknown): val is object =>
    typeof val === 'object';

export const isSetMember = <T>(set: Set<T>, maybeT: unknown): maybeT is T =>
    set.has(maybeT as T);

/**
 * A value that may be `null` but must be present.
 *
 * This type can be used to avoid accidentally-unmanaged inputs for values
 * of non-scalar types.
 */
export type Nullable<T> = T | null;

/**
 * Gets enumerable keys from an object, using the `for...in` statement. Should
 * generally only be used with enum objects, or with objects which are known to
 * be structurally identical at compile time and runtime.
 *
 * This function is not soundly typed for objects which may contain additional
 * keys at runtime. Please see `unsafeExample` below for one such case.
 *
 * See https://github.com/Microsoft/TypeScript/issues/12870
 *
 * @example
 * enum Role {
 *     Client,
 *     Server
 * }
 * const safeExample = getUnsafeKeys(Role);
 * // Type of `safeExample` is: ('Client' | 'Server')[]
 *
 * @example
 * interface Person {
 *     name: string;
 *     age: number;
 * }
 * const unsafeExample = (person: Person): void => {
 *     getUnsafeKeys(person).forEach(attr => {
 *         if (attr === 'name' || attr === 'age') n;
 *         assertNever(attr, null);
 *     });
 * };
 * const alice = {
 *     name: 'Alice',
 *     age: 45,
 *     occupation: 'Engineer',
 * };
 * unsafeExample(alice);
 */
export const getUnsafeKeys = <T extends string>(arg: {
    [P in T]: unknown;
}): T[] => {
    const keys: T[] = [];
    for (const k in arg) {
        keys.push(k as T);
    }
    return keys;
};

/**
 * Excludes a constructor.
 *
 * Useful when we need a type for a function without wanting to fallback to
 * `any` or `unknown`, but we don't want to use plain `Function` because it also
 * includes constructors.
 *
 * @example
 * function acceptsAFunction<T extends Function>(
 *     callback: NoConstructor<T>
 * ): void;
 *
 * acceptsAFunction(foo => {});
 * //               ^^^
 * // Parameter 'foo' implicitly has an 'any' type.(7006)
 *
 * class FooClass {}
 * acceptsAFunction(FooClass);
 * //               ^^^^^^^^
 * // Argument of type 'typeof FooClass' is not assignable to parameter of type
 * // 'never'.(2345)
 *
 * acceptsAFunction((foo: string) => {});
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type NoConstructor<T extends Function> = T extends new (
    ...args: unknown[]
) => unknown
    ? never
    : T;

/**
 * Pass in a type `T` to check that it is `never`. Useful for checking
 * invariants between types and static `const` data structures, e.g. that an
 * array contains all and only the keys in an interface.
 *
 * @example
 *
 * interface I {
 *   foo: number;
 *   bar: number;
 * }
 *
 * const keys = ['foo', 'bar'] as const;
 *
 * // type error when keys are missing from array.
 * assertTypeNever<Exclude<keyof I, typeof keys[number]>>();
 *
 * // type error when array has extra keys.
 * assertTypeNever<Exclude<typeof keys[number], keyof I>>();
 *
 *
 */
// @ts-ignore <- To suppress unused type parameter warning.
export const assertTypeNever = <T extends never>(): void => {};
