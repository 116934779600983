/*
* This file was generated by generateModuleTranslateFunction.ts.
* Do not edit this file as your change will be
* wiped out the next time the script runs.
*/

import { TOptions, t as i18nextT } from 'i18next';
import { TFunction } from '../../t';

import json from './countries.json';

/**
* A translation function for the `countries` namespace
* (`i18n/en/countries.json`),
* based on i18next's `t`, but with constraints for the keys and their parameters,
* and with support for pluralization.
*
* @param key A key from the `countries` namespace.
* @param optionsArgs Supported i18next options and required parameters for the
* given `key`.
* @returns The translated string.
*/
export const tCountries: TFunction<typeof json> = (key, ...optionsArgs) => {
    // Once the migration of our codebase to safe i18n types is completed
    // (EX-54666), we should be able to change this to:
    // const options: TOptions | undefined = optionsArgs[0];
    // This is needed because there are existing i18n keys with a `count`
    // interpolation param that is not formatted as a number, which
    // `TFunction` types as `Invalid`, which the native i18next types don't
    // like.
    const options = optionsArgs[0] as unknown as TOptions | undefined;
    return i18nextT(key, { ...options, ns: 'countries'});
};
