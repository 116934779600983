/**
 * @deprecated see UrlUtil for modern type-safe utilities.
 */

import * as UrlUtil from './UrlUtil';

export interface Params<V = unknown> {
    [key: string]: V;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UnsafeParams = Params<any>;

export const unsafePackUrl = (path: string, params: Params = {}): string =>
    UrlUtil.packUrl(path, params as unknown as UrlUtil.QsParams);

/**
 * Converts a map of name/value pairs into a key-sorted url-encoded
 * query string.
 */
export const unsafeEncodeQsParams = (params: Params): string =>
    UrlUtil.encodeQsParams(params as unknown as UrlUtil.QsParams);

/**
 * Get name/value pairs from current hash with any values.
 */
export const unsafeParseHash = (hash: string = location.hash): UnsafeParams =>
    UrlUtil.unpackUrl(hash).params;

/**
 * Get name/value pairs from current query string
 */
export const unsafeParseSearch = (
    search: string = location.search,
): UnsafeParams => UrlUtil.unpackUrl(search).params;

export const hashPath = (hash: string = location.hash): string =>
    // eslint-disable-next-line deprecation/deprecation
    UrlUtil.unpackUrl(hash).path.substr(1);
