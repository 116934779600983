/**
 * An error in the UI codebase. In general, we want to avoid these. However,
 * they can be useful during development, or for explicitly marking cases the
 * UI believes to be impossible.
 *
 * In the UI at runtime, these should show the "please contact support" message.
 *
 * @public
 */
export class CodeError extends Error {
    constructor(message: string) {
        super(message);
    }
}

/**
 * An error used to explain that a context is being used without a
 * corresponding provider.
 *
 * @public
 */
export class MissingReactProvider extends CodeError {
    constructor(providerName: string) {
        super(`This context requires a parent ${providerName} that is missing`);
    }
}

/**
 * An error used to mark a code path that is not currently implemented, but will
 * be before release.
 *
 * @public
 * @deprecated Implement all paths before shipping, or replace with a better error.
 */
export class UnimplementedError extends CodeError {
    /**
     * The JIRA ticket associated with doing the work to remove this error.
     */
    public readonly ticketId?: string;

    constructor(ticketIdOrMessage?: string, message: string = '') {
        if (isTicketId(ticketIdOrMessage)) {
            super(ticketIdOrMessage + (message && ` - ${message}`));
            this.ticketId = ticketIdOrMessage;
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        } else if (ticketIdOrMessage) {
            super(ticketIdOrMessage);
        } else {
            super('Not yet implemented');
        }
    }
}

/**
 * An error used to mark a place in the code that should never be reached.
 *
 * @public
 */
export class UnreachableError extends CodeError {
    constructor(public readonly context?: unknown) {
        super('Reached impossible point');
    }
}

/**
 * An error used to mark a code path with a problem in shipping code. This can
 * be used to connect customers to known issues where the cost of resolution is
 * substantially higher than the cost of detection.
 *
 * @public
 */
export class KnownIssueError extends CodeError {
    /**
     * @param ticketId The EX ticket that covers the fix for this issue.
     */
    constructor(public readonly ticketId: string) {
        super(`Encountered known issue ${ticketId}`);
    }
}

/**
 * Check if a string is an EX ticket ID.
 */
const isTicketId = (ticketId: string | undefined): ticketId is string =>
    ticketId !== undefined && /EX-\d+/.test(ticketId);

/**
 * An error used as a last-resort measure for aborting promise chains in old
 * code. This should never be used with new code, and is a serious code smell.
 *
 * @deprecated
 */
export class HackyPromiseChainAbort extends CodeError {
    constructor() {
        super('HackyPromiseChainAbort');
    }
}
