import { ApiClient } from '@extrahop/appliance-api';

import { AuthResponse, DisplayResponse, UnauthResponse } from './interface';
// eslint-disable-next-line extrahop/no-main-ui-imports
import { WireUser } from 'util/User';

const LOGIN_PATH = '/admin/auth/login/';

export const getLogin = async (): Promise<
    undefined | ((AuthResponse | UnauthResponse) & DisplayResponse)
> =>
    (
        await fetch(LOGIN_PATH, {
            method: 'GET',
        })
    ).json();

export const postLogin = async (
    user: string,
    pass: string,
    token: string,
): Promise<AuthResponse | UnauthResponse | undefined> => {
    const form = new URLSearchParams();
    form.set('username', user);
    form.set('password', pass);

    return (
        await fetch(LOGIN_PATH, {
            method: 'POST',
            body: form.toString(),
            headers: {
                accept: 'application/json',
                'X-CSRFToken': token,
                'Content-type': 'application/x-www-form-urlencoded',
            },
        })
    ).json();
};

export const agreeLicense = async (token: string): Promise<void> => {
    const form = new URLSearchParams();
    form.set('csrfmiddlewaretoken', token);
    form.set('accept', 'yes');
    return (
        await fetch('/admin/eula/', {
            method: 'POST',
            body: form.toString(),
            headers: {
                accept: 'application/json',
                'X-CSRFToken': token,
                'Content-type': 'application/x-www-form-urlencoded',
            },
        })
    ).json();
};

export const getUser = async (): Promise<WireUser> =>
    ApiClient.post('mgmt.getUserDetails');
