export const qsConfig: qs.IParseOptions & qs.IStringifyOptions = {
    strictNullHandling: true, // distinguish between empty strings and nulls
    allowDots: true, // cleaner URLs and backwards compat
    // XXX: note that https://github.com/ljharb/qs/issues/249 affects us
    /**
     * Set to an extremely high number to avoid array to object conversion.
     *
     * From qs docs: qs will also limit specifying indices in an array to a
     * maximum index of 20. Any array members with an index of greater than 20
     * will instead be converted to an object with the index as the key. This is
     * needed to handle cases when someone sent, for example, a[999999999] and
     * it will take significant time to iterate over this huge array.
     */
    arrayLimit: Number.MAX_SAFE_INTEGER,
};
