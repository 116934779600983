import { invert } from 'lodash-es';

import { assertNever, hasOptionalProperty } from '@extrahop/type-utils';

import { ApiClient } from './ApiClient';

export enum Type {
    Host = 'host',
    Uri = 'uri',
    Ip = 'ipaddr',
    Fingerprint = 'fingerprint',
}

export type StringThreatType = Type.Host | Type.Uri | Type.Fingerprint;

export const StringThreatType = {
    guard: (type: Type): type is StringThreatType => {
        switch (type) {
            case Type.Host:
            case Type.Uri:
            case Type.Fingerprint:
                return true;
            case Type.Ip:
                return false;
            default:
                return assertNever(type, false);
        }
    },
};

export enum Label {
    CloudService = 'cloud_service',
    ThreatIntel = 'threat_intel',
}

const invertedPatternTypeMap = invert(Type);

/**
 * Type guard for `PatternsApi.Type`.
 */
export const isPatternType = (val: string): val is Type =>
    invertedPatternTypeMap.hasOwnProperty(val);

/**
 * @see getPatterns for usage.
 * @see getPatternsDetail for usage.
 */
export interface Request {
    /** @example host, uri or ipaddr */
    type: Type;
    /** @example cloud service or threat intelligence */
    labels: Label[];
    /** @example '192.168.1.1' or 'http://www.google.com' */
    keys: string[];
}

/**
 * The result of a `getPatterns` API call. Represents a tuple of indexes,
 * where tuple[0] maps to `Request.keys` index, and tuple[1] maps to
 * `Request.labels` index.
 *
 * @see Request for corresponding indexes.
 */
export type Result = [number, number];

/**
 * Tests a set of `type` strings for matches against the all `labels` patterns
 * on the appliance.
 *
 * The result shows which request `keys` resulted in positive matches. The
 * result does not include any metadata about the matched patterns.
 *
 * @see getPatternsDetail for fetching additional pattern metadata on matches.
 */
export const getPatterns = async (
    { type, labels, keys }: Request,
    signal?: AbortSignal,
): Promise<Result[]> => {
    const postResult = await ApiClient.post<{
        result: { results: Result[] };
    }>(
        'bridge.getPatterns',
        {
            type,
            labels,
            keys:
                type === Type.Uri
                    ? keys.map(key => normalizeProxyUriKey(key))
                    : keys,
        },
        { signal },
    );
    return postResult.result.results;
};

/**
 * Indicates whether a pattern is part of a built-in collection or was uploaded
 * by an appliance user.
 */
export enum SourceType {
    BuiltIn = 'built-in',
    Integration = 'integration',
    User = 'user',
}

/**
 * The result of a `getPatternsDetail` API call.
 */
export interface DetailResult {
    key: number;
    label: Label;
    /**
     * Label-specific metadata associated with the matching pattern. For
     * user-uploaded TI, this is always null.
     */
    metadata: { [key: string]: string } | string | null;
    source_name: string;
    source_type: SourceType;
}

/**
 * Tests a set of `type` strings for matches against the all `labels` patterns
 * on the appliance.
 *
 * In addition to testing whether the strings matched, this method also returns
 * metadata about the pattern that was matched.
 *
 * @see getPatterns for boolean matching without fetching details.
 */
export const getPatternsDetail = async (
    { type, labels, keys }: Request,
    signal?: AbortSignal,
): Promise<DetailResult[]> => {
    const postResult = await ApiClient.post<{
        result: { results: DetailResult[] };
    }>(
        'bridge.getPatterns',
        {
            type,
            labels,
            keys:
                type === Type.Uri
                    ? keys.map(key => normalizeProxyUriKey(key))
                    : keys,
            options: { details: true },
        },
        { signal },
    );
    removeInternalMetadata(postResult.result.results);
    return postResult.result.results;
};

/**
 * Request structure for `patterns.Query`
 *
 * Each key besides `labels` is a {@link Type}, with an array
 * of threat values.
 */
export type PatternsQuery = { labels: Label[] } & Partial<
    Record<Type, string[]>
>;

/**
 * Response structure for each matching pattern in `patterns.Query`
 */
export type PatternMatch = Pick<
    DetailResult,
    'metadata' | 'source_name' | 'source_type'
> & {
    /**
     * Index into the request's array of values for the corresponding type
     */
    index: number;
    /** Index into the request's array for this label */
    label: number;
};

/** Response structure for `patterns.Query`  */
export type PatternsQueryResponse = Partial<Record<Type, PatternMatch[]>>;

const PatternsQueryResponse = {
    /** Just checks that the keys are correct */
    guard: (x: unknown): x is PatternsQueryResponse =>
        Object.values(Type).some(type => hasOptionalProperty(x, type)),
};

/**
 * Calls the `patterns.Query` API.
 */
export const getPatternsQuery = async (
    request: PatternsQuery,
    signal?: AbortSignal,
): Promise<PatternsQueryResponse> => {
    const normalizedUris = request[Type.Uri]?.map(normalizeProxyUriKey);
    const normalizedQuery = normalizedUris
        ? { ...request, [Type.Uri]: normalizedUris }
        : request;

    const resp = await ApiClient.post<unknown>(
        'patterns.Query',
        normalizedQuery,
        {
            signal,
        },
    );

    if (!PatternsQueryResponse.guard(resp)) {
        throw new TypeError('Unexpected patterns.Query response', {
            cause: { resp },
        });
    }

    for (const matches of Object.values(resp)) {
        removeInternalMetadata(matches);
    }
    return resp;
};

/**
 * Strips out {@link DetailResult.metadata} fields that start with "_" as those
 * are currently EH-specific internal fields.
 *
 * Mutates `detailResults`.
 */
const removeInternalMetadata = (
    detailResults: Pick<DetailResult, 'metadata'>[],
): void => {
    for (const { metadata } of detailResults) {
        if (typeof metadata === 'string' || metadata === null) {
            continue;
        }

        for (const field in metadata) {
            if (field.startsWith('_')) {
                delete metadata[field];
            }
        }
    }
};

export const _test = { removeInternalMetadata };

/**
 * Strips the proxy IP and scheme from a proxy request URI key, passing
 * non-proxy requests through unchanged. We do this because the Patterns API
 * expects a normal request and cannot handle our proxy request format.
 *
 * URI keys can take 3 different shapes:
 * - normal request: `www.example.org:123/path`
 * - proxy request: `1.2.3.4 http://www.example.org:123/path`
 * - connect request: `1.2.3.4 CONNECT 4.5.6.7:123`
 *
 * Note: only proxy requests have a URI scheme associated with them.
 *
 * Input example: `1.2.3.4 http://www.example.org:123/path`
 * Output example: `www.example.org:123/path`
 */
const proxyUriRegex = /^.+ (?:.+\:\/\/)?(?<uri>.+)$/;
const normalizeProxyUriKey = (proxyUri: string): string => {
    const matches = proxyUri.match(proxyUriRegex);
    if (matches === null) {
        return proxyUri;
    }
    return matches[1] ?? proxyUri;
};
