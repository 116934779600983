import { each, isArray } from 'lodash-es';
import moment from 'moment';

import {
    createLayeredFrame,
    LocalStorage,
    LocalStorageNameSpace,
} from '@extrahop/browser-utils';
import { ObservableField } from '@extrahop/observable-field';
import { hasProperty, isNonVoid, isString } from '@extrahop/type-utils';

import { getCsrfToken } from './CsrfToken';

const ERROR_SUPPRESSED_URIS = ['/cpcapi/keepAlive'];
const getInstanceId = (): string => {
    const random = Math.floor((1 + Math.random()) * 0x10000000);
    const id = random.toString(16).substring(1);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)['exInstanceId'] = id;
    return id;
};

// TODO remember current location (see EX-12165)
const forceLogin = (): void => window.location.assign('/extrahop/logout/');

const DOWNLOAD_URL_PREFIX = `${window.location.protocol}//${window.location.host}/extrahop`;
const DEFAULT_REQUEST_TIMEOUT = 0; // 0 means no timeout

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const instanceId = (window as any)['exInstanceId'] || getInstanceId();
const genid = Date.now();
let counter: number = 0;

const defaultHeaders = (): Headers => {
    const token = getCsrfToken();
    return {
        'Content-Type': 'application/json;charset=UTF-8',
        ...(token ? { 'X-CSRFToken': token } : {}),
    };
};

type Headers = Record<string, string>;

export interface RequestConfig {
    httpMethod?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'PUT';
    headers?: Headers;
    cache?: boolean;
    timeout?: number;
    signal?: AbortSignal;
}

interface Handlers {
    method: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    responseHandler(response: unknown): { response: {}; isError: boolean };
    requestHandler(
        method: string,
        args?: unknown,
        config?: RequestConfig,
    ): CancelableResponse;
}

interface CancelableResponse {
    xhr: XMLHttpRequest;
    promise: Promise<unknown>;
}

type PATH = string | (string | number)[];

export const ApiClientErrorRequest = new ObservableField<XMLHttpRequest | null>(
    null,
);

export interface ApiClientError {
    code: string;
    message: string;
}

export const ApiClientError = {
    guard: (x: unknown): x is ApiClientError =>
        hasProperty(x, 'code', isString) && hasProperty(x, 'message', isString),
};

class ApiClientClass {
    /**
     * Used to determine if this object is in debug mode. This prop is usually
     * set at the application root.
     */
    public isDebug: boolean | undefined = undefined;

    /**
     * Downloads a file using a hidden frame
     * `[protocol][host]/extrahop` is prepended automataically to path
     */
    // eslint-disable-next-line @typescript-eslint/ban-types
    public download(path: string, data: {}, csrfToken: string): void {
        createLayeredFrame(
            DOWNLOAD_URL_PREFIX + path,
            { ...data, frameId: 'ApiClient_Download' },
            csrfToken,
        );
    }

    public post<T = unknown, A = unknown>(
        method: PATH,
        args?: A,
        config: RequestConfig = {},
    ): Promise<T> {
        return this.ajax(method, args, {
            ...config,
            httpMethod: 'POST',
        }) as Promise<T>;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
    public amfResponse(response: any): {} {
        const result = response.result;
        const error = response.error;
        if (error) {
            if (error.code === 'AuthenticationError') {
                // user is not logged in
                forceLogin();
            }
            console.error('Error', error);
            return { isError: true, response: error };
        }
        return { response: result };
    }

    public getRequestCount(): number {
        return counter;
    }

    private buildHandlers(flexmethod: PATH): Handlers {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let requestHandler: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let responseHandler: any;

        // Support arrays for methods - joined with "/" to make a path.
        let method: string =
            typeof flexmethod === 'string' ? flexmethod : flexmethod.join('/');

        // Determine API to use
        if (
            method.indexOf('bridge.') === 0 ||
            method.indexOf('sc.') === 0 ||
            method.indexOf('topology.') === 0
        ) {
            // Return promise
            requestHandler = this.amfDispatch;
            responseHandler = this.apiResponse;
        } else {
            if (
                method === '/a/suggest' ||
                method === '/a/search' ||
                method === '/a/allmetrics' ||
                method.indexOf('/extrahop/reports/upload/manifest') === 0 ||
                method.indexOf('/cpcapi/') === 0
            ) {
                requestHandler = this.apiRequest;
                responseHandler = this.apiResponse;
            } else if (method.indexOf('admin.') === 0) {
                // get rid of admin prefix on method
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                method = method.split('.')[1]!;
                requestHandler = this.rpcRequest;
                responseHandler = this.rpcResponse;
            } else {
                requestHandler = this.amfRequest;
                responseHandler = this.amfResponse;
            }
        }
        return {
            requestHandler,
            responseHandler,
            method,
        };
    }

    /**
     * Performs non-angular requests and debounces responses
     * to avoid digest storms
     */
    private _ajax(
        uri: string,
        data?: unknown,
        requestConfig: RequestConfig = {},
    ): CancelableResponse {
        const request = new XMLHttpRequest();
        return {
            xhr: request,
            promise: new Promise((resolve, reject) => {
                // set the default headers
                const config: RequestConfig = {
                    ...requestConfig,
                    headers: { ...requestConfig.headers, ...defaultHeaders() },
                };

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                let cacheName: any;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                let cacheValue: any;
                let cacheLastModified;
                if (config.cache && LocalStorage.isSupported()) {
                    cacheName = 'util.ApiClient.url=' + uri;
                    cacheValue = LocalStorage.get(cacheName);
                    cacheLastModified = LocalStorage.get(
                        cacheName + '.Last-Modified',
                    );
                    if (config.headers && cacheValue && cacheLastModified) {
                        // manually add If-Modified-Since header
                        // (browsers usually don't use HTTP cache for XHR requests)
                        config.headers['If-Modified-Since'] = cacheLastModified;
                    }
                }

                counter++;
                request.open(config.httpMethod || 'POST', uri, true);

                // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/timeout
                // "In Internet Explorer, the timeout property may be set only
                // after calling the open() method and before calling the
                // send() method."
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                if (isNonVoid(requestConfig.timeout)) {
                    request.timeout = requestConfig.timeout;
                }

                request.onreadystatechange = () => {
                    // wait until request is done loading (or failing)
                    if (request.readyState !== 4) return;
                    let status = request.status;
                    let responseText = request.responseText;

                    // request failed
                    if (status === 0) {
                        reject(request);
                        if (navigator.onLine) return;
                        // client offline
                        ApiClientErrorRequest.set(request);
                    } else if (status === 401) {
                        // no valid session
                        reject({ code: String(status), message: responseText });
                        forceLogin();
                        return;
                    } else if (status >= 400) {
                        // For all other errors, display a generic error message
                        // This can potentially be due to inconsistencies in the api returning
                        // a non-401 for expired sessions, in which case they should be fixed on
                        // the server side to do so
                        reject({ code: String(status), message: responseText });
                        if (!ERROR_SUPPRESSED_URIS.includes(uri)) {
                            ApiClientErrorRequest.set(request);
                        }
                    } else {
                        ApiClientErrorRequest.set(null);
                    }

                    // request succeeded
                    if (config.cache) {
                        // manually handle caching
                        // (browsers usually don't use HTTP cache for XHR requests)
                        if (status === 304) {
                            // not modified
                            // replace response with cached value
                            status = 200;
                            responseText = cacheValue;
                        } else {
                            // set new cache value
                            cacheValue = responseText;
                            cacheLastModified =
                                request.getResponseHeader('Last-Modified');

                            if (
                                LocalStorage.isSupported() &&
                                cacheValue &&
                                cacheLastModified
                            ) {
                                try {
                                    LocalStorage.set(cacheName, cacheValue);
                                    LocalStorage.set(
                                        cacheName + '.Last-Modified',
                                        cacheLastModified,
                                    );
                                } catch (e) {
                                    console.error(
                                        'LocalStorage error. Calling ' +
                                            'LocalStorage.safeClear() in an ' +
                                            'attempt to resolve this issue.',
                                        e,
                                    );
                                    LocalStorage.safeClear([
                                        LocalStorageNameSpace.ApiClient,
                                    ]);
                                }
                            }
                        }
                    }

                    let response;
                    try {
                        response = JSON.parse(responseText);
                    } catch (e) {
                        reject({
                            code: 'Response Error',
                            message: 'Cannot parse response:' + responseText,
                        });
                        return;
                    }

                    if (status >= 200 && status < 300) {
                        resolve(response);
                    } else {
                        response.__status = status; // TODO hack to expose status
                        reject(response);
                    }
                };

                each(config.headers, (value: string, header: string) => {
                    request.setRequestHeader(header, value);
                });

                request.send(JSON.stringify(data));
            }),
        };
    }

    private async ajax(
        flexmethod: PATH,
        args?: unknown,
        config: RequestConfig = {},
    ): Promise<unknown> {
        const { requestHandler, responseHandler, method }: Handlers =
            this.buildHandlers(flexmethod);

        // Generate request
        const { promise, xhr }: CancelableResponse = requestHandler(
            method,
            args,
            { timeout: DEFAULT_REQUEST_TIMEOUT, ...config },
        );

        if (config.signal) {
            // The signal may have already been aborted, in which case adding an
            // event listener would not be enough to cancel the request.
            if (config.signal.aborted) {
                xhr.abort();
            } else {
                config.signal.addEventListener('abort', () => xhr.abort());
            }
        }

        const resp = await promise;

        const wrappedResponse = responseHandler(resp);
        if (wrappedResponse.isError) {
            throw wrappedResponse.response;
        }
        return wrappedResponse.response;
    }

    private readonly apiRequest = (
        method: string,
        args = {},
        config?: RequestConfig,
    ): CancelableResponse => this._ajax(method, args, config);

    private readonly rpcRequest = (
        method: string,
        args = {},
        config?: RequestConfig,
    ): CancelableResponse =>
        this._ajax('/admin/rpc/', { method, params: args }, config);

    private readonly amfRequest = (
        method: string,
        args: unknown = [],
        config?: RequestConfig,
    ): CancelableResponse =>
        this.amfDispatch(method, args instanceof Array ? args : [args], config);

    private readonly amfDispatch = (
        method: string,
        args?: unknown,
        config: RequestConfig = {},
    ): CancelableResponse => {
        Object.assign(config, {
            headers: {
                'X-Instance-Id': instanceId,
                'X-Gen-Id': genid,
            },
        });
        let uri =
            method.indexOf('bridge.') === 0 ||
            method === 'reclog.runQuery' ||
            method === 'reclog.RunRecordQuery' ||
            method === 'reclog.StartSearch' ||
            method === 'reclog.WaitForUpdates' ||
            method === 'reclog.Cancel' ||
            method === 'reclog.Resume' ||
            method === 'reclog.KeepAlive' ||
            method === 'config.getGeoCoords' ||
            method === 'sc.Query' ||
            method === 'topology.Query' ||
            method === 'patterns.Query'
                ? '/m/'
                : '/a/';
        if (this.isDebug) {
            uri += '?' + method;
        }

        // These methods expect only the params object and will fail if params
        // is wrapped in an array
        if (
            method === 'reclog.StartSearch' ||
            method === 'reclog.WaitForUpdates' ||
            method === 'reclog.Cancel' ||
            method === 'reclog.Resume' ||
            method === 'reclog.KeepAlive' ||
            method === 'patterns.Query'
        ) {
            const params = isArray(args) ? args[0] : args;
            return this._ajax(uri, { method, params }, config);
        }

        return this._ajax(uri, { method, params: args }, config);
    };

    /**
     * Wrap the response in an object with a response field
     */
    private readonly apiResponse = <R>(response: R): { response: R } => ({
        response,
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
    private readonly rpcResponse = (response: any): {} => {
        const error = response.error;
        if (error) {
            console.error('Error', error);
            return { isError: true, response: error };
        }
        const result = response.result;
        return { response: result };
    };
}

export const ApiClient = new ApiClientClass();
// Reduce go time format to largest common whole unit
// For example, the go string "-1y0w1d0m0s" will be converted to "-366d"
export const reduceApiDuration = (api: string): string => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parts: any = {};
    const pattern = /(\d+)([a-zA-Z])/g;
    const getters = [
        ['asDays', 'd'],
        ['asHours', 'h'],
        ['asMinutes', 'm'],
    ];
    let match;

    /* eslint-disable no-param-reassign */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((api as any)[0] === '-') api = api.slice(1); // remove initial "-"
    /* eslint-enable no-param-reassign */

    // I can't get this to work as an assignment outside the while.
    // Something magic going on here I don't understand.  I think it might
    // have to be this way for a /g match.
    // eslint-disable-next-line no-cond-assign
    while ((match = pattern.exec(api))) {
        // break up string into components, ignore zeros.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const val = parseInt(match[1]!, 10);
        if (val === 0) continue;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        parts[match[2]!] = val;
    }

    // Feed components to moment js.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const duration: any = moment.duration(parts);
    // Get value in largest units we support that are a whole number.
    for (const getter of getters) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const val = duration[getter[0]!]();
        if (val === Math.floor(val)) {
            return `-${val}${getter[1]}`;
        }
    }
    console.error(`cannot parse duration from rest api: ${api}`);
    return '-0m';
};
