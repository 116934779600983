import { once } from 'lodash-es';

import { ApiClient, RestApi } from '@extrahop/appliance-api';
import { MalformedResponseError } from '@extrahop/errors';
import { isListOf } from '@extrahop/type-utils';

import { Language } from './Interface';

/**
 * Fetch an *ordered* list of available language codes from the server. The
 * languages are ordered based on how commonly the language is used, and the
 * completeness of the translation. This list may be cloud updated.
 * @param includeDebug Whether to filter out debug language tags
 * @returns A list of available languages that should be surfaced in the UI
 */
export const fetchAvailableLanguages = once(
    async (includeDebug: boolean = false): Promise<Language[]> => {
        const request = 'config.getLanguageCodes';
        const response = await ApiClient.post(request, []);
        if (!isListOf(Language.guard)(response)) {
            throw new MalformedResponseError(request, response);
        }

        return response.filter(
            language =>
                // Filter out private use language tags that start with either
                // (a) an `x` subtag or (b) a subtag in the range `qaa`..`qtz`;
                // see RFC 5646 and private use entries in the IANA language
                // subtag registry. We also filter out `zz-ZZ`, since we have
                // used this as a debug language code as well (even though it's
                // technically not a private use language tag).
                includeDebug ||
                (!language.code.match(/^(q[a-t][a-z]|x)(-|$)/) &&
                    language.code !== 'zz-ZZ'),
        );
    },
);

/**
 * Update the language preference for the user. The user preference is returned
 * when querying `mgmt.getUserDetails`.
 * @param user The user to set the language preference for
 * @param preferredLanguage The language preference, which will be a language
 * tag or `null` (to indicate no preference)
 * @returns Promise for the update
 */
export const setPreferredLanguage = (
    user: string,
    preferredLanguage: string | null,
): Promise<void> => {
    const path = ['users', user, 'preferences'].join('/');
    return RestApi.patch(path, {
        language_code: preferredLanguage,
    });
};
