/*
* This file was generated by generateRollupEnglishResources.ts.
* Do not edit this file as your change will be
* wiped out the next time the script runs.
*/

export const DEFAULT_LANGUAGE_CODE = 'en';

export const resources = {
    [DEFAULT_LANGUAGE_CODE]: {
        activitygroups: require('./en/activitygroups.json'),
        activitymaps: require('./en/activitymaps.json'),
        admin: require('./en/admin.json'),
        alerts: require('./en/alerts.json'),
        alerts_settings: require('./en/alerts_settings.json'),
        api: require('./en/api.json'),
        appliance: require('./en/appliance.json'),
        bundles: require('./en/bundles.json'),
        cloud_console: require('./en/cloud_console.json'),
        components: require('./en/components.json'),
        countries: require('./en/countries.json'),
        custom_ids_rules: require('./en/custom_ids_rules.json'),
        custom_parameters: require('./en/custom_parameters.json'),
        customdevices: require('./en/customdevices.json'),
        dashboards: require('./en/dashboards.json'),
        detection_status_tracking: require('./en/detection_status_tracking.json'),
        detection_type_catalog: require('./en/detection_type_catalog.json'),
        detections: require('./en/detections.json'),
        device_model_set_details_popover: require('./en/device_model_set_details_popover.json'),
        device_overview: require('./en/device_overview.json'),
        devices: require('./en/devices.json'),
        devicessummary: require('./en/devicessummary.json'),
        dialog: require('./en/dialog.json'),
        dock: require('./en/dock.json'),
        error: require('./en/error.json'),
        exports: require('./en/exports.json'),
        firmware_upgrade: require('./en/firmware_upgrade.json'),
        forms: require('./en/forms.json'),
        groups: require('./en/groups.json'),
        investigations: require('./en/investigations.json'),
        ipaddress_information: require('./en/ipaddress_information.json'),
        keys: require('./en/keys.json'),
        metric_catalog: require('./en/metric_catalog.json'),
        metrics: require('./en/metrics.json'),
        nav: require('./en/nav.json'),
        networks: require('./en/networks.json'),
        notification_settings: require('./en/notification_settings.json'),
        object_types: require('./en/object_types.json'),
        objlist: require('./en/objlist.json'),
        onboarding: require('./en/onboarding.json'),
        operators: require('./en/operators.json'),
        overview_sec: require('./en/overview_sec.json'),
        packet_basics: require('./en/packet_basics.json'),
        packets: require('./en/packets.json'),
        platform: require('./en/platform.json'),
        prevent_navigation: require('./en/prevent_navigation.json'),
        product_keys: require('./en/product_keys.json'),
        protocol_pages: require('./en/protocol_pages.json'),
        protocol_splashes: require('./en/protocol_splashes.json'),
        reclog: require('./en/reclog.json'),
        records_settings: require('./en/records_settings.json'),
        regions: require('./en/regions.json'),
        remote_access: require('./en/remote_access.json'),
        search: require('./en/search.json'),
        settings: require('./en/settings.json'),
        severity: require('./en/severity.json'),
        shared_settings: require('./en/shared_settings.json'),
        systemnotices: require('./en/systemnotices.json'),
        threat_briefing: require('./en/threat_briefing.json'),
        time: require('./en/time.json'),
        toast_notifications: require('./en/toast_notifications.json'),
        triggers: require('./en/triggers.json'),
        users: require('./en/users.json'),
        util: require('./en/util.json'),
        validation: require('./en/validation.json'),
        vlans: require('./en/vlans.json'),
        widgets: require('./en/widgets.json'),
    },
    // Other languages are fetched from the server in the localization package
} as const;
