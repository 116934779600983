import { FALLBACK_LANGUAGE } from './Const';

/**
 * The user-agent's language. Should be resolved by the server, otherwise uses
 * the fallback language.
 */
let autoLanguage: string;

/**
 * Set the user-agent language. Should be resolved by the server.
 */
export const setAutoLanguage = (val: string): void => {
    if (!val) {
        console.error('auto-language cannot be blank', val);
        return;
    }
    autoLanguage = val;
};

/**
 * Get the user-agent language, as resolved by the server.
 */
export const getAutoLanguage = (): string =>
    autoLanguage || FALLBACK_LANGUAGE.code;
