import { appendScript } from '../../entrypoint/src/util';

let field: HTMLScriptElement | undefined;

export const addNoiseField = (): void => {
    if (field) return;
    field = appendScript('/media/js/noise-field.js');
};

export const removeNoiseField = (): void => {
    if (!field) return;
    field.parentElement?.removeChild(field);
    const main = document.getElementsByTagName('main')[0];
    if (main) document.body.removeChild(main);
};
