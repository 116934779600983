/** XXX: mvp ts conversion */

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function safeParseJson(
    json: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): { json: any | null; exception: Error | null } {
    try {
        return { json: JSON.parse(json), exception: null };
    } catch (e) {
        console.warn('Failed to parse json', e, json);
        return {
            json: null,
            exception:
                e === null
                    ? null
                    : e instanceof Error
                    ? e
                    : new Error(String(e)),
        };
    }
}

export { safeParseJson };
