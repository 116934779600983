// eslint-disable-next-line extrahop/no-restricted-imports
import { isFinite, memoize } from 'lodash-es';

/**
 * Convert number to string pixels; leave string untouched.
 *
 * @param  {String|Number} [length='']
 * @return {String}
 */
export const normalizeLength = (length: string | number): string => {
    if (typeof length === 'number') {
        return pixelsToString(length);
    }

    return length || '';
};

/**
 * Convert number of pixels to CSS string value.
 */
export const pixelsToString = (pixels: number): string =>
    isFinite(pixels) ? pixels + 'px' : '';

/**
 * Convert CSS string value to number of pixels.
 */
export const stringToPixels = (
    css: string | number,
    offsetParentWidth: number = 0,
): number | undefined => {
    if (typeof css === 'number') {
        return css;
    }

    if (css.slice(-1) === '%') {
        return Math.floor((parseInt(css, 10) * offsetParentWidth) / 100);
    }
    if (css.slice(-2) === 'px') {
        return parseInt(css, 10);
    }
};

/**
 * A dictionary of classNames -> boolean that will be included
 * if the boolean is true
 */
interface ClassDict {
    [className: string]: boolean | null | undefined;
}

/**
 * Returns a space-delimited string concatenation of all class names that have
 * a true condition.
 *
 * Any number of arguments may be passed. If an argument is null, undefined or
 * false, it will be ignored. If an argument is a string, it will be used in
 * the returned string. An argument can also be an object mapping class names
 * to their conditions, like for example:
 *
 * > classes{{ 'c-used': true, 'c-ignored': false }} // returns `c-used`.
 *
 * Nesting is not allowed on purpose to discourage complexity in the consumer
 * code. It's better to pass strings (the primitive for the `class` html
 * attribute) around.
 *
 * ```
 * interface MyComponentProps {
 *     className?: string;
 * }
 *
 * const MyComponent = ({ className }: MyComponentProps) =>
 *     <div className={classes('c-my-component', className)} />;
 *
 * <MyComponent className={classes('x', y, z)} />
 * ```
 *
 * If handling an array, spreading is always an option:
 *
 * > classes('class1`, ...otherClasses)
 *
 * Valid usage examples:
 *
 * > classes('class1', 'class2')
 * > classes('class1', 'class2', { class3: someBooleanValue })
 * > classes({ class3: isClickable && 'clickable' }, 'class1', 'class2')
 * > classes({ class3: someBooleanValue })
 * > classes('class1', null, undefined, { class4: someBoolean })
 */
export const classes = (
    ...args: (string | null | undefined | false | ClassDict)[]
): string =>
    args
        .flatMap(arg => {
            if (!arg) return [];
            if (typeof arg === 'string') return [arg];
            return Object.entries(arg)
                .filter(([, isValid]) => isValid)
                .map(([className]) => className);
        })
        .join(' ');

/**
 * Find the actual width of the scrollbar.
 * thanks https://davidwalsh.name/detect-scrollbar-width
 */
export const getScrollbarWidth = memoize((): number => {
    const testDiv = document.createElement('div');
    testDiv.style.width = '100px';
    testDiv.style.height = '100px';
    testDiv.style.overflow = 'scroll';
    testDiv.style.position = 'absolute';
    testDiv.style.top = '-9999px';
    document.body.appendChild(testDiv);
    const width = testDiv.offsetWidth - testDiv.clientWidth;
    document.body.removeChild(testDiv);
    return width;
});
