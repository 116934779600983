import { isFunction } from 'lodash-es';

import { hasProperty } from '@extrahop/type-utils';

/**
 * Interface used by errors that are sometimes retryable.
 */
export interface MaybeRetryable {
    /**
     * If defined, the error supports retrying the operation that produced it.
     * The exact behavior will vary by error, but it is expected that components
     * can call `retry` on an error and have all state updates for re-rendering
     * transparently handled by the error originator.
     */
    retry?(): void;
}

/**
 * Represents an error that might recover on a subsequent attempt. For example,
 * a timeout error would normally be retryable, but a malformed ID error would
 * not be.
 */
type Retryable = Required<MaybeRetryable>;

/**
 * Utility methods for working with `Retryable` errors.
 */
export const Retry = {
    /**
     * Assign a retry method to a value, typically an `Error`. This mutates the
     * value in-place to preserve the prototype chain, stack trace, et alia.
     */
    // eslint-disable-next-line @typescript-eslint/ban-types
    assign: <T extends {}>(value: T, retry: () => void): T & Retryable =>
        // We are deliberately using `assign` here to mutate the passed-in value
        // by adding the retry method in-place.
        // eslint-disable-next-line prefer-object-spread
        Object.assign(value, { retry }),
    /**
     * Type guard checking if retry is supported by the provided value.
     */
    isSupported: (x: unknown): x is Retryable =>
        x !== null && x !== undefined && hasProperty(x, 'retry', isFunction),
};
