import { resources } from '@extrahop/loc';

import { FALLBACK_LANGUAGE } from './Const';
import i18next, { TFunction } from 'i18next';
import Pseudo from 'i18next-pseudo';
import { initReactI18next } from 'react-i18next';

const RESOURCE_BUNDLE_URL_STEM = '/locale/ui_resource_bundles';
const getResourceBundleUrl = (lang: string): string =>
    `${RESOURCE_BUNDLE_URL_STEM}/${lang}.json`;

const loadedLanguages = new Set<string>([FALLBACK_LANGUAGE.code]);

export const initializeI18n = (): Promise<TFunction> => {
    const IS_DEV =
        window.location.search.indexOf('debugMode') > -1 ||
        process.env.NODE_ENV === 'development';

    return i18next
        .use(
            new Pseudo({
                enabled: IS_DEV,
                languageToPseudo: 'es',
                letterMultiplier: 4,
                repeatedLetters: ['B', 'o', 'a', 't'],
            }),
        )
        .use(initReactI18next)
        .init({
            debug: IS_DEV,
            fallbackLng: FALLBACK_LANGUAGE.code,
            ns: ['components'],
            defaultNS: 'components',
            detection: {
                order: ['querystring'],
                lookupQuerystring: 'lng',
            },

            interpolation: {
                escapeValue: false, // react already safe from xss => https://www.i18next.com/translation-function/interpolation#unescape
            },
            postProcess: IS_DEV ? ['pseudo'] : [],
            resources: {
                ...resources,
                ...(IS_DEV
                    ? {
                          // make es as a copy of en
                          // but target es for pseudo-localization
                          es: resources[FALLBACK_LANGUAGE.code],
                      }
                    : {}),
            },
        });
};

export const loadLanguageResourceBundles = async (
    languageCode: string,
): Promise<void> => {
    if (loadedLanguages.has(languageCode)) return;

    try {
        const apiResponse = await fetch(getResourceBundleUrl(languageCode));
        const body = await apiResponse.json();
        Object.keys(body).forEach(namespace => {
            i18next.addResourceBundle(
                languageCode,
                namespace,
                body[namespace],
                false,
                true,
            );
        });
        loadedLanguages.add(languageCode);
    } catch (e) {
        console.error(`Failed to load target language '${languageCode}'`, e);
    }
};
