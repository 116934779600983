import { tNav } from '@extrahop/loc';
import { hasProperty } from '@extrahop/type-utils';

import { AuthenticationErrorCode, AuthResponse } from './interface';

/** Add a script tag to the document for the provided path. */
export const appendScript = (
    path: string,
    onload: GlobalEventHandlers['onload'] = null,
): HTMLScriptElement => {
    const s = document.createElement('script');
    s.src = `${document.location.protocol}//${document.location.host}${path}`;
    s.defer = true;
    s.async = false;
    s.onload = onload;
    document.head.appendChild(s);
    return s;
};

const loadedCss: HTMLLinkElement[] = [];
export const awaitCSS = (path: string): Promise<void> =>
    new Promise(resolve => {
        const l = document.createElement('link');
        l.rel = 'stylesheet';
        l.type = 'text/css';
        l.href = path;
        l.onload = () => resolve();
        loadedCss.push(l);
        document.head.appendChild(l);
    });

export const unloadCss = (): void =>
    loadedCss.forEach(l => l.parentElement?.removeChild(l));

export const getFailureText = (
    code: AuthenticationErrorCode | string | undefined,
): string => {
    switch (code) {
        case AuthenticationErrorCode.Credentials:
            return tNav('landing.errors.credentials');
        case AuthenticationErrorCode.Permission:
            return tNav('landing.errors.permission');
        case AuthenticationErrorCode.Duplicate:
            return tNav('landing.errors.duplicate');
        case AuthenticationErrorCode.NoHopcloudRemoteAccess:
            return tNav('landing.errors.no_hopcloud_remote_access');
        case AuthenticationErrorCode.SSOExpired:
            return tNav('landing.errors.sso_expired');
        case AuthenticationErrorCode.HopcloudRaDisabled:
            return tNav('landing.errors.hopcloud_ra_disabled');
        default:
            console.error('Unknown authentication failure code:', code);
            return tNav('landing.errors.unknown');
    }
};

export const requiresOnboarding = (authDetails: AuthResponse): boolean =>
    hasInvalidLicense(authDetails) ||
    shouldChangeSetupShellPasswords(authDetails) ||
    shouldConnectToHopCloud(authDetails) ||
    canConnectToRx360(authDetails) ||
    canConnectToECA(authDetails) ||
    canConfigureTI(authDetails);

export const hasInvalidLicense = (authDetails: AuthResponse): boolean =>
    hasProperty(authDetails.ex_global_opts, 'license') &&
    hasProperty(authDetails.ex_global_opts.license, 'valid') &&
    authDetails.ex_global_opts.license.valid === false;

export const shouldChangeSetupShellPasswords = (
    authDetails: AuthResponse,
): boolean =>
    hasProperty(
        authDetails.ex_global_opts,
        'changeSetupShellDefaultPassword',
    ) && authDetails.ex_global_opts.changeSetupShellDefaultPassword === true;

export const shouldConnectToHopCloud = (authDetails: AuthResponse): boolean =>
    // user has changed default setup and shell passwords
    // this implies that the user is also a `setup` user
    hasProperty(
        authDetails.ex_global_opts,
        'changeSetupShellDefaultPassword',
    ) &&
    authDetails.ex_global_opts.changeSetupShellDefaultPassword === false &&
    // is licensed for hopcloud
    isLicensedForHopCloud(authDetails) &&
    // but is not connected to hopcloud
    hasProperty(authDetails.ex_global_opts, 'hopcloud') &&
    authDetails.ex_global_opts.hopcloud === false;

export const isLicensedForHopCloud = (authDetails: AuthResponse): boolean =>
    hasProperty(authDetails.ex_global_opts, 'license') &&
    hasProperty(authDetails.ex_global_opts.license, 'features') &&
    hasProperty(
        authDetails.ex_global_opts.license.features,
        'hopcloud_allowed',
    ) &&
    authDetails.ex_global_opts.license.features.hopcloud_allowed === true;

export const isCloudManagedEda = (authDetails: AuthResponse): boolean =>
    isDiscover(authDetails) && supportsHopcloudSwitchboard(authDetails);

const isDiscover = (authDetails: AuthResponse): boolean =>
    hasProperty(authDetails.ex_global_opts, 'systemManifest') &&
    hasProperty(authDetails.ex_global_opts.systemManifest, 'version') &&
    hasProperty(
        authDetails.ex_global_opts.systemManifest.version,
        'PLATFORM',
    ) &&
    authDetails.ex_global_opts.systemManifest.version.PLATFORM === 'extrahop';

const supportsHopcloudSwitchboard = (authDetails: AuthResponse): boolean =>
    hasProperty(authDetails.ex_global_opts, 'license') &&
    hasProperty(authDetails.ex_global_opts.license, 'features') &&
    hasProperty(
        authDetails.ex_global_opts.license.features,
        'hopcloud_switchboard',
    ) &&
    authDetails.ex_global_opts.license.features.hopcloud_switchboard === true;

export const canConnectToRx360 = (authDetails: AuthResponse): boolean =>
    // user is connected to hopcloud
    hasProperty(authDetails.ex_global_opts, 'hopcloud') &&
    authDetails.ex_global_opts.hopcloud === true &&
    // and is cloud managed EDA
    isCloudManagedEda(authDetails);

export const canConnectToECA = (authDetails: AuthResponse): boolean =>
    isDiscover(authDetails) && !supportsHopcloudSwitchboard(authDetails);

const isECA = (authDetails: AuthResponse): boolean =>
    hasProperty(authDetails.ex_global_opts, 'systemManifest') &&
    hasProperty(authDetails.ex_global_opts.systemManifest, 'version') &&
    hasProperty(
        authDetails.ex_global_opts.systemManifest.version,
        'PLATFORM',
    ) &&
    authDetails.ex_global_opts.systemManifest.version.PLATFORM === 'ecm';

const isEnterprise = (authDetails: AuthResponse): boolean =>
    hasProperty(authDetails.ex_global_opts, 'license') &&
    hasProperty(authDetails.ex_global_opts.license, 'features') &&
    hasProperty(
        authDetails.ex_global_opts.license.features,
        'hopcloud_switchboard',
    ) &&
    authDetails.ex_global_opts.license.features.hopcloud_switchboard === false;

export const canConfigureTI = (authDetails: AuthResponse): boolean =>
    isLicensedForHopCloud(authDetails) &&
    isECA(authDetails) &&
    isEnterprise(authDetails);

// https://stackoverflow.com/questions/10687099/how-to-test-if-a-url-string-is-absolute-or-relative/57047786#57047786
export const isSameOrigin = (url: string): boolean =>
    new URL(document.baseURI).origin === new URL(url, document.baseURI).origin;
