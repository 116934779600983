import { isNil, sortBy, trim, trimEnd, trimStart } from 'lodash-es';
import qs from 'qs';
import { useDebugValue, useMemo } from 'react';
import { useLocation } from 'react-router';

import { qsConfig } from './qsConfig';

type QsPrimitive = string | null | undefined;

/**
 * This interface represents a javascript object that is (mostly)
 * lossless when encoded/decoded with the qs library. Lossy exceptions
 * are:
 * 1) Empty arrays
 * 2) Props with undefined values. We include undefined in this interface
 * so that objects with optional properties are compatible.
 */
export interface QsParams {
    [key: string]: QsParams | QsPrimitive | (QsPrimitive | QsParams)[];
}

/**
 *
 * We use `encodeURIComponent` to encode our path parameters and
 * `react-router` uses `decodeURI` to decode our path parameters
 * `decodeURI` doesn't decode a few characters that are encoded
 * by `encodeURIComponent` so we have to do it ourselves
 * https://github.com/ReactTraining/react-router/issues/4917
 * @param param param to decode
 */
export const decodePathParam = (param: string): string =>
    param
        .replace(/%2F/g, '/')
        .replace(/%26/g, '&')
        .replace(/%3B/g, ';')
        .replace(/%2C/g, ',')
        .replace(/%3F/g, '?')
        .replace(/%3A/g, ':')
        .replace(/%40/g, '@')
        .replace(/%3D/g, '=')
        .replace(/%2B/g, '+')
        .replace(/%24/g, '$')
        .replace(/%23/g, '#');

/**
 * Convert parameters into a query string. Result is not prefixed with '?'.
 * @see decodeQsParams.
 */
export const encodeQsParams = (params: QsParams): string => {
    const queryString = qs.stringify(params, qsConfig);
    // In the future we should be able to just return queryString but for
    // now we need to sort to make Flex happy:
    return sortBy(
        queryString.split('&'),
        (param: string) => param.split('=')[0],
    ).join('&');
};

/**
 * Convert string to parameters.
 * @see encodeQsParams.
 */
export const decodeQsParams = (queryString: string): QsParams =>
    qs.parse(
        queryString[0] === '?' ? queryString.slice(1) : queryString,
        qsConfig,
    ) || {};

/**
 * Convert path/params into a url. Result is not prefixed with '#'.
 * @see unpackUrl
 */
export const packUrl = (path: string, params?: QsParams): string => {
    const encoded = encodeQsParams(params || {});
    if (encoded === '') return path;
    return [path, encoded].join('?');
};

/**
 * Convert url into path/params.
 * @see packUrl
 * @see unpackQsParams for identical functionality but just params.
 */
export const unpackUrl = (url: string): { path: string; params: QsParams } => {
    if (!url.includes('?')) return { path: url, params: {} };
    const [path, ...queryStringParts] = url.split('?');
    const queryString = queryStringParts.join('?');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return { path: path!, params: decodeQsParams(queryString) };
};

/**
 * Update the hash query string to include `newParams` and no other params.
 *
 * @param replaceCurrentHistoryEntry if true, will replace the current browser
 * history entry rather than creating a new one.
 */
export const setHashParams = (
    newParams: QsParams,
    replaceCurrentHistoryEntry: boolean = false,
): void => {
    const { path } = unpackUrl(location.hash);
    setHash(packUrl(path.slice(1), newParams), replaceCurrentHistoryEntry);
};

/**
 * Update the hash query string. Replaces parameters specified in newParams.
 * Other parameters will be left as-is.
 *
 * @param replaceCurrentHistoryEntry if true, will replace the current browser
 * history entry rather than creating a new one.
 */
export const mergeHashParams = (
    newParams: QsParams,
    replaceCurrentHistoryEntry: boolean = false,
): void => {
    const { path, params } = unpackUrl(location.hash);
    const mergedParams = { ...params, ...newParams };
    setHash(packUrl(path.slice(1), mergedParams), replaceCurrentHistoryEntry);
};

/**
 * Update the hash path and query string.
 * @see setHashParams for updating query string only
 *
 * @param replaceCurrentHistoryEntry if true, will replace the current browser
 * history entry rather than creating a new one.
 */
export const setHash = (
    newHashUrl: string,
    replaceCurrentHistoryEntry: boolean = false,
): void => {
    const hash = `#${newHashUrl}`;
    if (hash === window.document.location.hash) return;
    if (replaceCurrentHistoryEntry) {
        window.document.location.replace(hash);
    } else {
        window.document.location.assign(hash);
    }
};

/**
 * Mimics qs.stringify(boolean). This is useful when constructing a QsParams
 * object.
 * @see QsParams
 * @see encodeQsParams
 * @see stripNilsAndEmptyArrays for a similar helper function
 * @see qsStringifyNumber for a similar helper function
 * @public
 */
export const qsStringifyBoolean = (
    bool: boolean | null | undefined,
): string | undefined => (isNil(bool) ? undefined : String(bool));

/**
 * Mimics qs.stringify(number). This is useful when constructing a QsParams
 * object.
 * @see QsParams
 * @see encodeQsParams
 * @see stripNilsAndEmptyArrays for a similar helper function
 * @see qsStringifyBoolean for a similar helper function
 */
export const qsStringifyNumber = (
    num: number | null | undefined,
): string | undefined => (isNil(num) ? undefined : String(num));

// Source: https://stackoverflow.com/questions/10687099/how-to-test-if-a-url-string-is-absolute-or-relative#comment101754558_19709846
const absoluteUrlTest = /^(\/\/|[a-z]+:)/i;
export const isAbsoluteUrl = (url: string): boolean =>
    absoluteUrlTest.test(url);

/**
 * Returns `false` only if the given URL is not a relative and the `host` part
 * is different from the one in the current location.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/URL/host
 */
export const isExternalUrl = (
    maybeUrl: string,
    currentLocation: Pick<Location, 'host'> = window.location,
): boolean => {
    try {
        const url = new URL(maybeUrl);
        return url.host !== currentLocation.host;
    } catch (e) {
        return false;
    }
};

/**
 * Normalizes a hashPath string so that any leading # or trailing '/' is removed
 */
export const normalizeHashPath = (hashPath: string): string =>
    hashPath.replace(/(^#)|(\/$)/g, '');

/**
 * Returns the query string from the current location as an object.
 * The object will only change if the search string in the location changes.
 */
export const useQueryParams = (): QsParams => {
    const { search } = useLocation();
    const params = useMemo(() => decodeQsParams(search), [search]);
    useDebugValue(params);
    return params;
};

/**
 * Takes a string or an array of strings and joins them together as a url
 * path, separated by `/`. Removes empty string elements. Preserves leading
 * and trailing '/' if given.
 */
export const flattenPath = (path: string | string[]): string => {
    if (typeof path === 'string') return path.replace(/\/\/+/g, '/');
    const pathParts = path.filter(part => part.length > 0);
    return pathParts
        .map((part, i) => {
            if (i === 0) return trimEnd(part, '/');
            if (i === pathParts.length - 1) return trimStart(part, '/');
            return trim(part, '/');
        })
        .join('/');
};
