import $ from 'jquery';

import { isNonVoid } from '@extrahop/type-utils';

const TEXT_SIZE_SAFETY_LIMIT = 100;
let canvasContext: CanvasRenderingContext2D | undefined = undefined;

/**
 * Canvas for calculating text width without resorting to DOM insertion.
 *
 * @param   {String} font
 * @returns {CanvasRenderingContext2D}
 */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions, @typescript-eslint/explicit-function-return-type
function _getCanvasContext(font: string) {
    if (!canvasContext) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        canvasContext = document.createElement('canvas').getContext('2d')!;
    }
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!canvasContext) {
        throw new Error('Failed to instantiate canvasContext');
    }
    canvasContext.font = font;
    return canvasContext;
}

// https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeType
const nodeTypes = {
    ELEMENT_NODE: 1,
    TEXT_NODE: 3,
};

/**
 * Prepends zero-width spaces to non alpha/numeric/space characters
 * to help encourage good line breaking behavior
 *
 *    Uses Node.nodeValue to extract text
 *      https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeValue
 *
 *    Uses [^\w\s] to detect any non-word non-whitespace characters
 *      https://developer.mozilla.org/en-US/docs/Web/JavaScript
 *          /Reference/Global_Objects/RegExp
 *
 *    Uses Node.replaceChild() to update node contents
 *      https://developer.mozilla.org/en-US/docs/Web/API/Node/replaceChild
 *
 * @param {Node} node - DOM node to scrape for text
 */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions, @typescript-eslint/explicit-function-return-type
export function addLineBreakPoints(node: HTMLElement) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!(node && node.childNodes && node.childNodes.length)) {
        return;
    }
    // IE 11 doesn't implement NodeList.prototype.forEach, so we borrow from
    // Array.
    Array.prototype.forEach.call(node.childNodes, (childNode: HTMLElement) => {
        switch (childNode.nodeType) {
            case nodeTypes.ELEMENT_NODE:
                // recurse inward
                addLineBreakPoints(childNode);
                break;

            case nodeTypes.TEXT_NODE:
                if (childNode.nodeValue === null) {
                    throw new Error('nodeValue is null.'); // should never happen for a TEXT_NODE
                }
                // we only care about non alpha/numeric/space characters
                if (!childNode.nodeValue.match(/[^\w\s]/)) {
                    return;
                }
                // replace text node with <span> element
                // holding text nodes and <wbr> elements
                let textBuffer = '';
                const nodeValue = childNode.nodeValue;
                const newChild = document.createElement('span');
                for (let i = 0; i < nodeValue.length; i++) {
                    const char = nodeValue[i];
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    if (char!.match(/[^\w\s]/)) {
                        // breakable character, add <wbr>
                        if (textBuffer.length) {
                            // first, add any text in buffer
                            newChild.appendChild(
                                document.createTextNode(textBuffer),
                            );
                            textBuffer = '';
                        }
                        // add <wbr> element
                        newChild.appendChild(document.createElement('wbr'));
                    }
                    textBuffer += char;
                }
                // add any leftover text
                if (textBuffer.length) {
                    newChild.appendChild(document.createTextNode(textBuffer));
                }
                // replace text node with our new thing
                node.replaceChild(newChild, childNode);
                break;
        }
    });
}

/**
 * Get closest ancestor of element.
 *
 * @example
 * th = document.querySelector('th');
 * thead = DOM.getClosestParent(th, 'thead', document.body);
 *
 * @param   {Element}  el            - start with this element
 * @param   {String}   parentTagName - look for this parent tag name ('div')
 * @param   {Element} [stopEl]       - stop looking at this element
 * @returns {Element}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, prefer-arrow/prefer-arrow-functions, @typescript-eslint/explicit-function-return-type
export function getClosestParent(el: any, parentTagName: any, stopEl: any) {
    // eslint-disable-next-line no-param-reassign
    parentTagName = parentTagName.toUpperCase();
    while (el && el !== stopEl) {
        if (el.tagName === parentTagName) {
            return el;
        }
        // eslint-disable-next-line no-param-reassign
        el = el.parentElement;
    }
    return undefined;
}

/**
 * Determine if the node associated with the currently selected text is a
 * descendant of the provided HTMLElement.
 *
 * Answers the question: is text selected in this element (or its descendants).
 */
export const hasSelectedText = (el: HTMLElement): boolean => {
    const windowSelection = window.getSelection();
    return (
        isNonVoid(windowSelection) &&
        windowSelection.type === 'Range' &&
        isNonVoid(windowSelection.focusNode) &&
        el.contains(windowSelection.focusNode)
    );
};

/**
 * Find width of text with given font. Defaults to '13px Lato'.
 */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function getTextWidth(text: string, font?: string): number {
    // eslint-disable-next-line no-param-reassign
    font = font || '13px Lato';
    return _getCanvasContext(font).measureText(text).width;
}

/**
 * Find font size needed to fit text within width
 * Note: just for shrinking... font size unchanged if text already fits
 * Warn: fontProps.size must be passed in as a number, no 'px' appended
 * Okay: Yes the name is long but later we may grow fit, fit height, etc
 *
 * @param  {String} text        Text content
 * @param  {Number} targetWidth Width to fit text within
 * @param  {Object} fontProps   Font properties of text in situ
 * @return {Number}             Font size resulting in fit text
 */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions, @typescript-eslint/explicit-function-return-type
export function getTextSizeToShrinkFitWidth(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    text: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    targetWidth: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fontPropsRef: any,
) {
    const fontProps = { ...fontPropsRef };
    let font = __getFontFromProps(fontProps);
    let currentWidth = getTextWidth(text, font);
    if (currentWidth < targetWidth) {
        return fontProps.size;
    }
    // Try to scale font proportionally
    fontProps.size *= targetWidth / currentWidth;
    font = __getFontFromProps(fontProps);
    currentWidth = getTextWidth(text, font);
    // Then loop until it works
    // (proportional seems to work in Chrome, but Firefox needs more love)
    let count = 0;
    while (currentWidth > targetWidth && count < TEXT_SIZE_SAFETY_LIMIT) {
        fontProps.size -= 0.5;
        font = __getFontFromProps(fontProps);
        currentWidth = getTextWidth(text, font);
        count++;
    }
    return fontProps.size;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, prefer-arrow/prefer-arrow-functions, @typescript-eslint/explicit-function-return-type
export function __getFontFromProps(fontProps: any) {
    let font = '';
    font += fontProps.style ? fontProps.style + ' ' : '';
    font += fontProps.weight ? fontProps.weight + ' ' : '';
    font += fontProps.size ? fontProps.size + 'px ' : '';
    font += fontProps.family ? fontProps.family : '';
    return font;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-function-return-type
export const copyHref = (ev: any) => {
    ev.preventDefault();
    const temp = $('<input>')
        .attr({ type: 'text', value: ev.target.href })
        .appendTo('body');
    (temp[0] as HTMLInputElement).select();
    // eslint-disable-next-line deprecation/deprecation
    document.execCommand('copy');
    temp.remove();
};

type NotText<T> = Exclude<T, Text>;

const isNotText = <T = unknown>(x: T): x is NotText<T> => !(x instanceof Text);

export const isNotTextOrThrow = <T = unknown>(x: T): x is NotText<T> => {
    if (isNotText(x)) return true;
    throw new Error('Unexpected Text node given.');
};

export const isFocused = (element: HTMLElement | null): boolean =>
    Boolean(
        document.hasFocus() &&
            element &&
            element.contains(document.activeElement),
    );
