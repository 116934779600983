import { MaybeRetryable } from './Retryable';

/**
 * An error caused by the UI being unable to reach the appliance. Refer to the
 * subclasses of this error to determine the more specific connectivity problem.
 *
 * @public
 */
export class ConnectivityError extends Error implements MaybeRetryable {
    constructor(
        message: string,
        public readonly retry?: () => void,
    ) {
        super(message);
    }
}

/**
 * An error caused by the client device being offline, as self-reported by the
 * browser through the `navigator.onLine` property.
 *
 * @public
 */
// Fun fact: The `fetch` API represents this case as a `TypeError`.
export class NetworkConnectivityError extends ConnectivityError {
    constructor(retry?: () => void) {
        super('You are not connected to the internet', retry);
    }
}

/**
 * An error caused by API connectivity problems which occur when the browser
 * reports that the device is online.
 *
 * @public
 */
export class ApplianceConnectivityError extends ConnectivityError {
    constructor(retry?: () => void) {
        super('You are not connected to the appliance', retry);
    }
}
