/** XXX: mvp ts conversion */
import $ from 'jquery';

/**
 * Create a layered iframe
 *
 * @param {string} url   The URL to open with
 * @param {Object} specs Specs for the window to open
 *
 */
export const createLayeredFrame = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    url: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    csrfToken: string,
): void => {
    let $frame = $('#' + data.frameId);

    if (!data.csrfmiddlewaretoken) {
        data.csrfmiddlewaretoken = csrfToken;
    }

    if (!$frame.length) {
        // Create invisible frame in the DOM but outside the viewport.
        // `display: none` would prevent its contents from being able to
        // accurately calculate their size.
        $frame = $('<iframe></iframe>').attr({
            id: data.frameId,
            class: 'is-layered is-invisible is-offgrid',
            frameborder: '0',
            marginheight: '0',
            marginwidth: '0',
            name: data.frameId,
        });
        $frame.appendTo(document.body);
    }

    // create form to post data to iframe
    const $frameProxy = $('<form>', {
        action: url,
        target: data.frameId,
        method: 'POST',
        id: 'frame-proxy',
    }).appendTo(document.body);

    // add data to form
    for (const field in data) {
        $('<textarea></textarea>')
            .attr({
                name: field,
                id: field,
            })
            .val(data[field])
            .appendTo($frameProxy);
    }

    // submit form, then clear it
    $frameProxy.submit().remove();
};
