import { isNonVoid } from '@extrahop/type-utils';

import { formatForMessage } from './Util';

/**
 * Error for when a resource (e.g. a detection, a metric source, a dashboard) is
 * requested by ID and not found. The error includes the resource type string
 * and the ID.
 *
 * @public
 */
export class NotFoundError<THandle> extends Error {
    constructor(
        /**
         * The type of resource that `handle` identifies.
         */
        public readonly resource: string,
        /**
         * The resource identifier which failed to resolve.
         */
        public readonly handle: THandle,
        additionalMessage?: string,
    ) {
        super(
            `Unable to find ${resource} ${formatForMessage(handle)}${
                additionalMessage ? '. ' + additionalMessage : ''
            }`,
        );
    }
}

/**
 * Error for when a resource is requested by ID and not found and the UI knows
 * that the resource has been deleted. The UI typically only knows this in one
 * of two cases:
 *
 * * The server sends a status code of "410/Gone" or equivalent
 * * The UI was the one that just deleted the resource
 *
 * @public
 */
export class DeletedError<THandle> extends NotFoundError<THandle> {
    constructor(resource: string, handle: THandle) {
        super(resource, handle, 'It has been deleted.');
    }
}

/**
 * Error for when a handle is statically known to be incorrect, such as a source
 * that has a negative number as its OID. These should never be retryable.
 *
 * @public
 */
export class MalformedHandleError<THandle> extends Error {
    /**
     * @param additionalInfo Additional information about why the handle cannot
     * resolve to a resource.
     */
    constructor(
        public readonly resource: string,
        public readonly handle: THandle,
        additionalInfo?: string,
    ) {
        super(
            `Handle for ${resource} cannot be ${formatForMessage(handle)}` +
                (additionalInfo ? `: ${additionalInfo}` : ''),
        );
    }
}

/**
 * Error for when a resource (e.g. a detection, a metric source, a dashboard) is
 * requested, and the response we get does not pass a type guard. The error
 * includes the resource type string and (optionally) the ID.
 *
 * @public
 */
export class MalformedResponseError<THandle> extends Error {
    constructor(
        public readonly resource: string,
        public readonly handle?: THandle,
        additionalMessage?: string,
    ) {
        super(
            (isNonVoid(handle)
                ? `Unable to find ${resource} ${formatForMessage(handle)}`
                : `Unable to fetch ${resource}`) +
                (additionalMessage ? `. ${additionalMessage}` : ''),
        );
    }
}

/**
 * Error for when all selected sites are offline and there is no data to show
 *
 * @public
 */
export class SiteUnavailableError extends Error {
    constructor(message: string) {
        super(message);
    }
}
