// eslint-disable-next-line extrahop/no-restricted-imports
import { isNaN } from 'lodash-es';
import { useEffect, useState } from 'react';

const testKey = '__testLocalStorageSupport__';
let isSupported = false;

try {
    window.localStorage.setItem(testKey, 'value');
    window.localStorage.removeItem(testKey);
    isSupported = true;
} catch (e) {
    console.error(
        'Local storage support test failed. Will avoid ' +
            'use of local storage',
    );
}

/**
 * The prefix of a key in a localstorage
 */
export enum LocalStorageNameSpace {
    ApiClient = 'util.ApiClient',
    WidgetModelCycle = 'summary.WidgetModel.cycle',
    UpdateNotification = 'UPDATE_NOTIFICATION',
    ThreatBriefingNotification = 'threatbriefing.notification',
}

type StorageEventHandler = (newval: string | null, event: StorageEvent) => void;

class LocalStorageClass {
    private readonly listeners: Map<string, Set<StorageEventHandler>> =
        new Map();

    public isSupported(): boolean {
        return isSupported;
    }

    /**
     * Set value in localStorage
     * Will throw exception if the given value is not a string
     *
     * If `dispatchEvent` is `true`, this will trigger a `storage` event on
     * `window`. Normally this event is only dispatched when *other* sessions
     * make changes to localStorage. This can be useful if you have a component
     * that depends on `onStorage` returning the updated value.
     */
    public set(
        key: string,
        value: string,
        dispatchEvent: boolean = false,
    ): string {
        this.validateKey(key);
        const oldValue = window.localStorage.getItem(key);
        window.localStorage.setItem(key, value);
        if (dispatchEvent) {
            window.dispatchEvent(
                new StorageEvent('storage', {
                    key,
                    oldValue,
                    newValue: value,
                    storageArea: localStorage,
                    url: window.location.toString(),
                }),
            );
        }
        return value;
    }

    /**
     * Get value from localStorage
     */
    public get(key: string): string | null {
        this.validateKey(key);
        return window.localStorage.getItem(key);
    }

    /**
     * Removes target pair from localStorage
     */
    public remove(key: string): string | null {
        let value = null;
        if (window.localStorage.hasOwnProperty(key)) {
            value = this.get(key);
            window.localStorage.removeItem(key);
        }
        return value;
    }

    /**
     * Clears localStorage of entries which start with the given namespaces.
     */
    public safeClear(namespaces: LocalStorageNameSpace[]): void {
        Object.keys(window.localStorage).forEach((k: string) => {
            for (const i in namespaces) {
                const lp = namespaces[i];
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                if ((k || '').indexOf(lp!) === 0) {
                    this.remove(k);
                }
            }
        });
    }

    /**
     * Finds the keys in Local storage with the given namespaces
     */
    public safeFindKeys(namespace: LocalStorageNameSpace): string[] {
        return Object.keys(window.localStorage).filter(key =>
            key.includes(namespace),
        );
    }

    /**
     * Clears localStorage entirely.
     * This should be used with extreme caution.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    public clear() {
        window.localStorage.clear();
    }

    /**
     * Registers the provided callback to be invoked when the specified
     * key is changed (in this tab/window *or* another).
     *
     * Returns a function which deregisters the callback when invoked.
     *
     * Note: The storage event is only fired if a different browser session
     * was responsible for the change.
     */
    public onStorage = (
        key: string,
        callback: StorageEventHandler,
    ): (() => void) => {
        const callbacks = this.listeners.get(key) || new Set();

        // If this is the first, register for events.
        if (this.listeners.size === 0) {
            window.addEventListener('storage', this.storageHandler);
        }

        // If it's the first for this key, register the key.
        if (callbacks.size === 0) {
            this.listeners.set(key, callbacks);
        }

        // Add the new listener for this key.
        callbacks.add(callback);
        return () => this.offStorage(key, callback);
    };

    /**
     * Return a deregister function.
     */
    public offStorage = (key: string, callback: StorageEventHandler): void => {
        const callbacks = this.listeners.get(key);

        if (!callbacks || !callbacks.has(callback)) {
            console.error(
                'Attempt to deregister a LocalStorage callback that is not' +
                    ' registered.',
            );
            return;
        }

        // Remove the listener.
        callbacks.delete(callback);

        // If this is the last listening for this key, remove it.
        if (callbacks.size === 0) {
            this.listeners.delete(key);
        }

        // If this is the last listener, deregister for events.
        if (this.listeners.size === 0) {
            window.removeEventListener('storage', this.storageHandler);
        }
    };

    // Test/debug hook.
    public __listeners = (): Map<string, Set<StorageEventHandler>> =>
        this.listeners;

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    private validateKey(key: string) {
        let err = '';
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (typeof key !== 'string') {
            err = 'given non-string';
        } else if (key === '') {
            err = 'given empty string';
        } else if (isNaN(key)) {
            err = 'given NaN';
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        } else if (key === null) {
            err = 'given null';
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        } else if (key === undefined) {
            err = 'given undefined';
        }
        if (err) {
            throw new Error('LocalStorage key validation failed: ' + err);
        }
    }

    /**
     * Handle storage events, call the relevant callbacks.
     */
    private readonly storageHandler = (e: StorageEvent): void => {
        if (!e.key) return;
        const callbacks = this.listeners.get(e.key);
        if (!callbacks) return;
        callbacks.forEach((callback: StorageEventHandler) =>
            callback(e.newValue, e),
        );
    };
}

export const LocalStorage = new LocalStorageClass();

export const useLocalStorageKey = (key: string): null | string => {
    const [value, setValue] = useState(
        LocalStorage.isSupported() ? LocalStorage.get(key) : null,
    );
    useEffect(() => {
        if (!LocalStorage.isSupported()) return;
        const dispose = LocalStorage.onStorage(key, setValue);
        return dispose;
    }, [key, setValue]);
    return value;
};
