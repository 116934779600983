import { DEFAULT_LANGUAGE_CODE } from '@extrahop/loc';

/**
 * Fallback language that we guarantee to always be available. This is mostly to
 * assist with situations where the server gives an unexpected response when
 * fetching available languages.
 */
export const FALLBACK_LANGUAGE = {
    code: DEFAULT_LANGUAGE_CODE,
} as const;
