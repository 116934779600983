import { isEqual, without } from 'lodash-es';

type Listener<T> = (newVal: T, oldVal?: T) => void;

type Hook<T> = (newVal: T) => T;

type Unbind = () => void;

/**
 * Represents a value that can be subscribed to for updates.
 *
 * @example
 * const favoriteAnimal = new ObservableField<Animal | null>(null);
 * favoriteAnimal.onUpdate(animal => console.log(`New favorite: #{animal}`));
 * favoriteAnimal.set(Animal.Dog);
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export class ObservableField<T = {} | undefined> {
    private listeners: Listener<T>[];
    private changeListeners: Listener<T>[];
    private setHooks: Hook<T>[];

    constructor(private tmp: T) {
        this.listeners = [];
        this.changeListeners = [];

        // Set-hooks allow the field's value to be transformed before it is set
        this.setHooks = [];
    }

    public get(): T {
        return this.tmp;
    }

    public set(newVal: T, silent?: boolean): void {
        const oldVal = this.get();
        // eslint-disable-next-line deprecation/deprecation
        this.tmp = this.runHooks(newVal);
        if (silent) {
            return;
        }
        this.listeners.forEach(fn => fn(newVal, oldVal));
        if (
            // Don't bother comparing if no change listeners - short circuit.
            this.changeListeners.length === 0 ||
            isEqual(this.tmp, oldVal)
        ) {
            return;
        }
        this.changeListeners.forEach(fn => fn(this.tmp, oldVal));
    }

    /**
     * Subscribe to this `ObservableField` for updates. Called when `set` or
     * `trigger` are called.
     */
    public onUpdate(fn: Listener<T>, $scope?: ng.IScope): Unbind {
        this.listeners.push(fn);
        // Optional scope automatically unsubscribes when destroyed.
        if ($scope) {
            $scope.$on('$destroy', () => this.offUpdate(fn));
        }
        return () => this.offUpdate(fn);
    }

    /**
     * Identical to `onUpdate`, but only called if `isEqual(new, old)` returns false.
     */
    public onChange(fn: Listener<T>, $scope?: ng.IScope): Unbind {
        this.changeListeners.push(fn);
        if ($scope) {
            $scope.$on('$destroy', () => this.offChange(fn));
        }
        return () => this.offChange(fn);
    }

    public offChange(fn?: Listener<T>): void {
        if (!fn) {
            this.changeListeners.length = 0;
        } else {
            this.changeListeners = without(this.changeListeners, fn);
        }
    }

    public offUpdate(fn?: Listener<T>): void {
        if (!fn) {
            this.listeners.length = 0;
        } else {
            this.listeners = without(this.listeners, fn);
        }
    }

    public reset(): void {
        this.offUpdate();
        this.offChange();
        this.setHooks = [];
    }

    /**
     * Exposed for testing purposes.
     */
    public getUpdateListenerCount(): number {
        return this.listeners.length;
    }

    /**
     * Exposed for testing purposes.
     */
    public getChangeListenerCount(): number {
        return this.changeListeners.length;
    }

    /**
     * @deprecated (tech debt: EX-28571)
     *
     * Instead, integrate your rules where the observable value is .set().
     */
    public addHook(fn: Hook<T>): void {
        this.setHooks.push(fn);
    }

    /**
     * Force all listeners to re-run.
     *
     * @deprecated this shouldn't be necessary in any sane design.
     */
    public trigger(newVal: T, oldVal: T): void {
        this.listeners.forEach(fn => fn(newVal, oldVal));
    }

    private runHooks(newVal: T): T {
        // eslint-disable-next-line no-param-reassign
        this.setHooks.forEach(fn => (newVal = fn(newVal)));
        return newVal;
    }
}
