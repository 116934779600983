import { safeParseJson, UnsafeUrlUtil } from '@extrahop/encoding-utils';
import {
    ApplianceConnectivityError,
    NetworkConnectivityError,
} from '@extrahop/errors';
import { hasProperty, isString } from '@extrahop/type-utils';

import { getCsrfToken } from '../../appliance-api/src/CsrfToken';

export const REST_API_PREFIX = '/api/v1';

/**
 * An error explicitly returned by the REST API.
 */
export class ApiError<T = unknown> extends Error {
    constructor(
        /** The parsed body of the response. */
        public readonly json: T,
        /** The raw response object exposed by the `fetch` API. */
        public readonly response: Response,
    ) {
        super(
            isRestApiErrorDefault(json)
                ? json.json.error_message
                : response.statusText,
        );
    }

    /**
     * The HTTP status code of the response.
     */
    public get status(): number {
        return this.response.status;
    }
}

/**
 * Typical REST API error message format and associated error type
 */
interface ApiErrorMessageBody {
    error_message: string;
}

export type ApiErrorDefault = ApiError<ApiErrorMessageBody>;

export const isRestApiErrorDefault = (
    error: unknown,
): error is ApiErrorDefault =>
    hasProperty(error, 'json') &&
    hasProperty(error.json, 'error_message', isString);

/**
 * Describes the parts of an API request with failed in a partially successful
 * REST API request.
 */
interface PartialSuccessFailure {
    action: string;
    id: string;
    reason: string;
}

/**
 * REST API response on partially successful updates - HTTP status code 207.
 */
export interface PartialSuccess {
    failures: PartialSuccessFailure[];
    message: string;
}

// TODO: Add a comment explaining what is going on here.
// Traced it to commit c7550d6dcebd8edfd5ad8ae7d101a07635d53d69
const getInstanceId = (): string => {
    const random = Math.floor((1 + Math.random()) * 0x10000000);
    const id = random.toString(16).substring(1);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)['exInstanceId'] = id;
    return id;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const instanceId = (window as any)['exInstanceId'] || getInstanceId();
const genid = Date.now();

const headers = {
    'X-Instance-Id': instanceId,
    'X-Gen-Id': `${genid}`,
    Authorization: 'Cookie',
};

const request = async <T>(
    method: string,
    path: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    options: RequestInit = {},
    internal?: boolean,
    omitContentType?: boolean,
): Promise<T> => {
    // eslint-disable-next-line no-param-reassign
    if (path.startsWith('/')) path = path.slice(1);

    // If the browser tells us we're offline, believe it and don't bother
    // trying to contact the appliance.
    if (!navigator.onLine) throw new NetworkConnectivityError();

    const token = getCsrfToken();
    const fetchOptions: RequestInit = {
        ...options,
        method,
        headers: {
            ...headers,
            ...(token ? { 'X-CSRFToken': token } : {}),
            // Content-Type required by newer versions of Django
            ...(omitContentType
                ? {}
                : { 'Content-Type': 'application/json;charset=UTF-8' }),
        },
        credentials: 'include',
    };

    const prefix = internal ? '/api/internal' : REST_API_PREFIX;

    if (data instanceof FormData) {
        fetchOptions.body = data;
    } else if (data) {
        fetchOptions.body = JSON.stringify(data);
    }

    try {
        const response = await fetch(`${prefix}/${path}`, fetchOptions);
        // Using .text() because .json() crashes when response is emtpy
        const text = await response.text();
        let json = text ? safeParseJson(text).json || text : null;

        // XXX: Making up for the fact that some POST calls are expected to
        // return an object with an id. This should be handled on the server.
        // Filed EX-24520 to take care of it.
        const location: string | null = response.headers.get('Location');
        if (response.status === 201 && text === '' && location) {
            const tokens = location.split('/');
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            json = { id: parseInt(tokens[tokens.length - 1]!, 10) };
        }

        // XXX: Handle a similar case as above if the response is 202. This is
        // used by the firmware download API, which returns a location to a job.
        if (response.status === 202 && text === '' && location) {
            json = { location };
        }

        // XXX: We should return the entire response and let the user
        // deal with it (EX-27423).  Until then, a hack:
        if (response.status === 207 /* multi-status */) {
            json.status = response.status;
        }

        if (response.ok) return json;

        throw new ApiError(json, response);
    } catch (err) {
        // If the caller cancelled the request, then we don't need to look at
        // the error any deeper.
        if (err instanceof DOMException && err.name === 'AbortError') {
            throw err;
        }

        if (!(err instanceof ApiError)) {
            throw err;
        }

        // Error responses from the API will have an ok property equal to false.
        // Network errors however will not have this ok field.
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!(err.response && !err.response.ok)) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            throw navigator.onLine
                ? new ApplianceConnectivityError()
                : new NetworkConnectivityError();
        }

        if (err.response.status === 401) {
            // Redirect to login if not authenticated (e.g. cookie expired)
            window.location.reload();
        }

        throw err;
    }
};

/**
 * Perform a `GET` operation on the server.
 *
 * @param options Additional options to pass to `fetch`. The most important of
 * these is `signal` - this allows the caller to declare that they have lost
 * interest in the results of the operation.
 */
export const get = <T = unknown>(
    path: string,
    data?: UnsafeUrlUtil.UnsafeParams | null,
    options: RequestInit & { internal?: boolean } = {},
): Promise<T> => {
    const { internal, ...fetchOpts } = options;
    const query = data ? `?${UnsafeUrlUtil.unsafeEncodeQsParams(data)}` : '';
    return request<T>('GET', path + query, null, fetchOpts, internal);
};

/**
 * Perform a `POST` operation on the server.
 *
 * @param options Additional options to pass to `fetch`. The most important of
 * these is `signal` - this allows the caller to declare that they have lost
 * interest in the results of the operation. Boolean option `omitContentType`
 * can also be set here to tell request not to specify a 'ContentType' header,
 * which is included by default.
 */
export const post = <T = unknown>(
    path: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any,
    options: RequestInit & { omitContentType?: boolean } = {},
): Promise<T> => {
    const { omitContentType, ...fetchOpts } = options;
    return request('POST', path, data, fetchOpts, undefined, omitContentType);
};

/**
 * Perform a `PUT` operation on the server.
 *
 * @param options Additional options to pass to `fetch`. Boolean option
 * `omitContentType` can be set here to tell request not to specify a
 * 'ContentType' header, which is included by default.
 */
// Cancellation with `signal` is deliberately not supported, as there is no way
// of knowing whether or not the request is cancelled before the change is
// applied.
export const put = <T = unknown>(
    path: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    options: { omitContentType?: boolean } = {},
): Promise<T> => {
    const { omitContentType } = options;
    return request('PUT', path, data, undefined, undefined, omitContentType);
};

/**
 * Perform a `PATCH` operation on the server.
 */
// Cancellation with `signal` is deliberately not supported, as there is no way
// of knowing whether or not the request is cancelled before the change is
// applied.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const patch = <T = unknown>(path: string, data: any): Promise<T> =>
    request('PATCH', path, data);

/**
 * Perform a `DELETE` operation on the server.
 */
// Cancellation with `signal` is deliberately not supported, as there is no way
// of knowing whether or not the request is cancelled before the deletion is
// applied.
export const remove = <T = unknown>(path: string): Promise<T> =>
    request('DELETE', path, null);
