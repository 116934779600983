/**
 * During the initial GET request, DisplayDetails is included in the response,
 * which is UnauthResponse or AuthResponse.  During subsequent POST requests,
 * only UnauthResponse or AuthResponse are returned.
 */

import { tNav } from '@extrahop/loc';
import {
    changeActiveLanguage,
    initializeI18n,
    setAutoLanguage,
} from '@extrahop/localization';
import { hasProperty, isBoolean } from '@extrahop/type-utils';

import { getLogin, getUser } from './api';
import {
    AuthResponse,
    hasPasswordExpired,
    isAuthenticated,
    isResponse,
} from './interface';
import { removeNoiseField } from './NoiseField';
import {
    appendScript,
    awaitCSS,
    isSameOrigin,
    requiresOnboarding,
    unloadCss,
} from './util';

/** Are we on /admin/ - load the admin ui if authorized. */
const isAdmin = document.location.pathname.split('/')[1] === 'admin';
const search = new URLSearchParams(document.location.search);
/** Did the url tell us where to go next? */
const next = search.get('next');
/** Did the url tell us why authentication failed? */
const reason = search.get('reason');
/**
 * Did the url provide a more detailed string as to why authentication failed?
 * This will be provided from the backend only when the request originates from
 * Hopcloud.
 * */
const reason_str = search.get('reason_str');

const DEFAUT_ADMIN_URL = '/admin/';

const isHeadless = (authDetails: AuthResponse): boolean =>
    hasProperty(authDetails.ex_global_opts, 'license') &&
    hasProperty(authDetails.ex_global_opts.license, 'features') &&
    hasProperty(
        authDetails.ex_global_opts.license.features,
        'headless_sensor',
    ) &&
    isBoolean(authDetails.ex_global_opts.license.features.headless_sensor) &&
    authDetails.ex_global_opts.license.features.headless_sensor;

const authenticated = async (
    authDetails: AuthResponse,
    loginRoot: HTMLElement,
): Promise<void> => {
    (window as typeof window & { _exGlobalOpts: unknown })['_exGlobalOpts'] =
        authDetails.ex_global_opts;

    if (hasPasswordExpired(authDetails)) {
        // Password expired, send user to password reset admin page
        document.location.assign(authDetails.next);
        return;
    }

    if (next) {
        // seems like an Open Redirect attack
        // just send the user to a default admin URL
        if (!isSameOrigin(next)) {
            document.location.assign(DEFAUT_ADMIN_URL);
            return;
        }
        document.location.assign(next);
        return;
    }

    if (isAdmin) return loadAdmin();

    // All UI traffic on EFC1290 and IDS should be directed to the admin UI
    if (isHeadless(authDetails)) {
        document.location.replace('/admin/');
        return;
    }

    const user = await getUser().catch(err => {
        if (err.code === '503') {
            // Service unavailable likely due to examf being down during
            // appliance migration, redirect to appliance migration status
            // page instead of failing with a blank page
            window.location.pathname = '/admin/appliance_migration_status';
        }
        return undefined;
    });

    const isUserNamedSetup = user?.username === 'setup';

    setAutoLanguage(authDetails.auto_language);
    await changeActiveLanguage(authDetails.language, true);

    if (
        // we don't want to enter OOBE
        // if the user is looking at the main app
        document.location.hash.length === 0 &&
        isUserNamedSetup &&
        requiresOnboarding(authDetails)
    ) {
        const onboardingModule = await import(
            /* webpackChunkName: "Onboarding" */ './onboarding/Onboarding'
        );
        onboardingModule.showOnboarding({
            loginRoot,
            awaitCSS,
            authDetails,
        });
        return;
    }

    // remove `dark theme` before loading the main app
    document.body.classList.remove('t-dark');

    return loadMain(authDetails);
};

const loadMain = async (authDetails: AuthResponse): Promise<void> => {
    // Set up DOM for app.  This must happen before importing the
    // app, as some app modules look for it upon import.
    document.documentElement.setAttribute('ng-controller', 'PageCtrl as page');
    const appRoot = document.createElement('div');
    appRoot.id = 'app';
    appRoot.classList.add('c-page');
    // hide for now to see loading/noise field
    appRoot.style.display = 'none';
    document.body.append(appRoot);

    if (document.location.pathname.split('/')[2] === 'popout') {
        document.documentElement.classList.add('popout');
    }

    // Show loading message:
    const loading = document.createElement('div');
    loading.className = 'c-global-loading';
    const loadingText = document.createElement('div');
    loadingText.classList.add('c-global-loading__text');
    loadingText.innerText = tNav('landing.loading');
    loading.append(loadingText);
    document.body.append(loading);

    // Load it, this takes some time.
    const module = await import(
        /* webpackPrefetch: true, webpackChunkName: "app" */ 'app'
    );

    // Remove loading message:
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (loading) document.body.removeChild(loading);

    // Stop login css from interfering with main ui:
    document.body.classList.remove('edition-security');
    document.body.style.backgroundColor = '';
    unloadCss();
    removeNoiseField();

    appRoot.style.display = ''; // show again

    // Add live demo script, if given.
    if (authDetails.live_demo_script) {
        const script = document.createElement('script');
        script.innerHTML = authDetails.live_demo_script;
        document.body.append(script);
    }
    module.appBoot();
};

const loadAdmin = async (): Promise<void> => {
    // Still at login path (no next=), go to admin index.
    if (document.location.pathname === '/admin/login/') {
        document.location.assign('/admin/' + document.location.search);
        return;
    }
    const module = await import(/* webpackChunkName: "admin" */ 'admin-app');
    // Stop login css from interfering with main/admin ui:
    unloadCss();
    removeNoiseField();
    module.appBoot();
};

const boot = async (): Promise<void> => {
    await initializeI18n();

    // Create container dom node here (rather than in react) so noise field
    // can continue spinning seamlessly while accepting license & logging
    // in.  main is "owned" by noise-field.js while container is "owned" by
    // react LoginForm or LicenseForm.
    const main = document.createElement('main');
    document.body.append(main);
    const loginRoot = document.createElement('div');
    loginRoot.classList.add('container');
    main.append(loginRoot);

    // Login is always black, but we can't default that way in the
    // template because admin is white and loads for normal navigation.
    if (!isAdmin) {
        document.body.style.backgroundColor = '#000000';
        // Noise field needs this to sniff theme:
        document.body.classList.add('edition-security');
        document.body.classList.add('t-dark');
    }

    let response: Awaited<ReturnType<typeof getLogin>>;
    while (!response || response.eula_path) {
        // This loop shouldn't loop more than once, it's here to re-fetch
        // response after the eula is accepted.
        try {
            response = await getLogin();
            // eslint-disable-next-line no-empty
        } catch {}

        if (!isResponse(response)) {
            console.error('Unexpected response from login api:', response);
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (response?.eula_path) {
            const eula = await import(
                /* webpackChunkName: "LicenseForm" */ './LicenseForm'
            );
            await eula.awaitLicenseAgreement(
                loginRoot,
                response.csrf_token,
                response.display_details,
                response.eula_path,
            );
        }
    }

    // `next` indicates we were redirected here for auth.
    // Always show login form - existing auth no good - avoid loop.
    if (isAuthenticated(response) && !next) {
        authenticated(response, loginRoot);
        return;
    }
    const appleconnect = response.display_details.saml_providers.find(
        provider => provider.name === 'appleconnect',
    );
    if (appleconnect) {
        document.location.assign(appleconnect.url);
        return;
    }

    // Auth has failed, proceed with login form:
    const loginModule = await import(
        /* webpackChunkName: "LoginForm" */ './LoginForm'
    );
    loginModule.showLoginForm({
        loginRoot,
        awaitCSS,
        appendScript,
        response,
        authenticated,
        next,
        reason,
        reason_str,
        isAdmin,
    });
};

document.addEventListener('DOMContentLoaded', boot);
