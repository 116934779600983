import { hasProperty, isBoolean } from '@extrahop/type-utils';

export interface DisplayDetails {
    autocomplete: boolean;
    edition_brand_name: string;
    firmware_version: string;
    is_hopcloud_request: false;
    marketing_brand_name: string;
    saml_providers: { name: string; url: string }[];
    supports_hopcloud_switchboard: boolean;
    running_config_ui_section?: {
        force_noise_field_pause?: boolean;
    };
    // It's provided, but we don't use it - hardcoded to dark.
    // theme: ThemeKeyBinary;
}

export interface DisplayResponse {
    display_details: DisplayDetails;
}

interface Response {
    csrf_token: string;
    eula_path?: string;
}

export enum AuthenticationErrorCode {
    Credentials = 'credentials',
    Permission = 'permission',
    Duplicate = 'duplicate',
    NoHopcloudRemoteAccess = 'no_hopcloud_remote_access',
    SSOExpired = 'sso_expired',
    HopcloudRaDisabled = 'hopcloud_ra_disabled',
}

export interface UnauthResponse extends Response {
    is_logged_in: false;
    failure_reason?: AuthenticationErrorCode;
}

export interface AuthResponse extends Response {
    is_logged_in: true;
    ex_global_opts: unknown;
    live_demo_script: string;
    /**
     * The backend resolves the user language based on ExtraHop user settings
     * and the user-agent settings.
     */
    language: string;
    /**
     * The backend resolves an auto_language based on the user-agent settings.
     * This is useful to display to the user so that they know what their
     * automatically-chosen language will be in the absence of an ExtraHop user
     * setting.
     */
    auto_language: string;
}

export interface PasswordExpiredResponse extends Response {
    is_logged_in: true;
    password_expired: true;
    next: string;
}

export const isResponse = (
    response: unknown,
): response is AuthResponse | UnauthResponse | PasswordExpiredResponse =>
    hasProperty(response, 'is_logged_in', isBoolean);

export const hasPasswordExpired = (
    response: unknown,
): response is PasswordExpiredResponse =>
    hasProperty(response, 'password_expired', isBoolean) &&
    response.password_expired;

export const isAuthenticated = (response: unknown): response is AuthResponse =>
    isResponse(response) && response.is_logged_in;
